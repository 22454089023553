import { ApolloError } from "@apollo/client";
import { match, Link } from "react-router-dom";
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { transformPath } from "../../helper/transformPath";
import { Sequence } from "../../interfaces/models/TaskSequence";
import Loading from "../Loading";
import { getImageUrl } from "../../helper/getImageUrl";

interface TaskSequenceProps {
  loading: boolean;
  error: ApolloError;
  sequences: Sequence[];
  match: match<{}>;
}

export function TaskSequences({
  loading,
  error,
  sequences,
  match,
}: TaskSequenceProps) {
  return (
    <>
      {loading && <Loading />}
      {error && <Alert color="danger">{error.message}</Alert>}
      {sequences && sequences?.length === 0 && (
        <Alert color="warning">No sequences found</Alert>
      )}
      <div className="w-100">
        <Breadcrumb listTag="div">
          <BreadcrumbItem>Home</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Row
        style={{ minHeight: "70vh", justifyContent: "center" }}
        className="animated-card"
      >
        {sequences?.map((sq: Sequence, i: number) => {
          if (sq.include_in_rotation === true) {
            return (
              <Col key={sq._id} xs={12} lg={4} className="w-100 my-3">
                <Link to={`${match.path}/${transformPath(sq._id)}`}>
                  <Card
                    key={i}
                    className="position-relative notification-cats-card hovering-effect mb-4 mb-xl-0 h-100 overflow-hidden shadow-lg overlay"
                    style={{
                      //@ts-ignore
                      "--backgroundImage": `url(${getImageUrl(sq?.image)})`,
                    }}
                  >
                    <CardBody className="card-background d-flex justify-content-center align-items-center text-center">
                      <h5 className="display-4 text-uppercase text-muted text-white">
                        {sq.name}
                      </h5>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            );
          }
          return null;
        })}
      </Row>
    </>
  );
}
