import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Collapse } from "reactstrap";
import PaginationMenu from "../../components/Tables/PaginationMenu";
import { User } from "../../interfaces/models/User";

interface UserTrackingTableProps {
  tableOpen: boolean;
  title: string;
  description: string;
  tableData: User[];
  rowViewButton: any;
  sortState: { field: string; order: SortOrder };
}

const { SearchBar } = Search;

export default function UserTrackingTable({
  tableOpen,
  title,
  description,
  tableData,
  rowViewButton,
  sortState,
}: UserTrackingTableProps) {
  return (
    <Collapse isOpen={tableOpen} className="p-4">
      <h2>{title}</h2>
      <span >{description}</span>
      <ToolkitProvider
        data={tableData}
        keyField="_id"
        columns={[
          {
            dataField: "name",
            text: "Name",
            sort: true,
          },
          {
            dataField: "button",
            text: "",
            sort: false,
            formatter: rowViewButton,
          },
        ]}
        search
      >
        {(props) => (
          <div className="py-4">
            <div
              id="datatable-basic_filter"
              className="dataTables_filter pb-1"
            >
              <label>
                Search:
                <SearchBar
                  className="form-control-sm"
                  placeholder=""
                  {...props.searchProps}
                />
              </label>
            </div>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              pagination={PaginationMenu}
              bordered={false}
              sort={{
                dataField: sortState.field,
                order: sortState.order,
              }}
            />
          </div>
        )}
      </ToolkitProvider>
    </Collapse>
  );
}
