import React from "react";
import { Container } from "reactstrap";
const Header = () => {
  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body"></div>
        </Container>
      </div>
    </>
  );
};

export default Header;
