import { Row } from "reactstrap";
import { getImageUrl, isUrlValidImage } from "../../helper/getImageUrl";

interface TaskImagesProps {
  imageUrls: string[];
  imageAlt: string;
}

// Conditionally render either one or two images, based upon the number of steps (either one or two at this stage)
export function TaskImages({ imageUrls, imageAlt }: TaskImagesProps) {
  return (
    <Row className="justify-content-center">
      {imageUrls.map((img) => {
        return (
          <div
            key={img}
            style={{
              paddingTop: "33.4%",
              height: "50%",
              width: "50%",
              overflow: "hidden",
            }}
            className="position-relative"
          >
            <img
              style={{
                objectFit: "contain",
                top: "0px",
                bottom: "0px",
                maxHeight: "100%",
              }}
              className="w-100 position-absolute"
              src={isUrlValidImage(img) ? img : getImageUrl(img)}
              alt={imageAlt}
            />
          </div>
        );
      })}
    </Row>
  );
}
