import {
  CardBody,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { timeFormat } from "d3-time-format";
import { DisplayMode } from "./UserTableInterfaces";
import { useState } from 'react'

interface UserTableHeaderProps {
  displayMode: DisplayMode;
  setDisplayMode: (value: DisplayMode) => void;
  inspectMode: boolean;
  setInspectMode: (value: boolean) => void;
  periods: any[];
}

export default function UserTableHeader({displayMode, setDisplayMode, inspectMode, setInspectMode, periods}: UserTableHeaderProps) {
  const formatTime = timeFormat("%d-%b-%Y");
  const formatMonth = timeFormat("%b-%Y");


  const [dropdownOpenInspect, setDropdownOpenInspect] = useState(false);
  const toggleInspect = () => setDropdownOpenInspect(prevState => !prevState);

  const [dropdownSummarType, setDropdownSummarType] = useState(false);
  const toggleOpenSummarType = () => setDropdownSummarType(prevState => !prevState);
  
  return (
    <CardBody>
      <div className="input-slider-container">
        <div className="slider input-slider--" id="sliderRegular"></div>
        <br></br>
        <div className="slider input-slider--" id="sliderDouble"></div>
      </div>
      <Row className="align-items-start mb-3">
        <Col lg="6" xl="6">
          <h2>{DisplayMode[displayMode]} Summary</h2>
          <span>
            Average performance summary for all users
          </span>
        </Col>
        <Col
          lg="6"
          xl="6"
          className="d-flex justify-content-start justify-content-lg-end pr-0 mt-3 mt-lg-0"
        >
          <ButtonDropdown className="mr-1" toggle={toggleInspect} isOpen={dropdownOpenInspect}>
            <DropdownToggle
              color="var(--gray)"
              className="btn btn-secondary  dropdown-toggle"
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                toggle={false}
                onClick={() => setInspectMode(!inspectMode)}
              >
                <i className={inspectMode ? `fa fa-eye` : `fa fa-eye-slash`} />
                Inspect mode
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>

          {periods.length > 0 && (
            <Button className="btn" style={{ minWidth: 220 }}>
              {periods[0] !== periods[1]
                ? `${formatTime(periods[0])} to ${formatTime(periods[1])}`
                : `${formatMonth(periods[0])}`}
              <i className="fa fa-calendar ml-2"></i>
            </Button>
          )}

          <ButtonDropdown  toggle={toggleOpenSummarType} isOpen={dropdownSummarType}>
            <DropdownToggle
              color="var(--gray)"
              className="btn btn btn-secondary dropdown-toggle"
              style={{ minWidth: 150 }}
            >
              {DisplayMode[displayMode]} Summary
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setDisplayMode(DisplayMode.Weekly)}>
                Weekly Summary
                {displayMode === DisplayMode.Weekly && (
                  <i className="fa fa-check text-primary ml-2"></i>
                )}
              </DropdownItem>
              <DropdownItem onClick={() => setDisplayMode(DisplayMode.Monthly)}>
                Monthly Summary
                {displayMode === DisplayMode.Monthly && (
                  <i className="fa fa-check text-primary ml-2"></i>
                )}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
      </Row>
    </CardBody>
  );
}
