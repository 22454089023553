import ReactMarkdown from "react-markdown";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";
import StepZilla from "react-stepzilla";
import Loading from "./Loading";
import { WorkFromHomeChecklist } from "../assets/data/WorkFromHomeChecklist";
import { ErgonomicChecklist } from "../assets/data/ErgonomicChecklist";
import { StandingChecklist } from "../assets/data/StandingChecklist";
import {
  ackChecklist as ackCheck,
  getChecklist,
} from "../graphql/queries/queries";

interface OnboardChecklistProps {
  isOpen: boolean;
  onClose: () => void;
}

const OnboardChecklist = (props: OnboardChecklistProps) => {
  const [ackChecklist] = useMutation(ackCheck, {
    onCompleted: () => {
      checklistStatus.refetch();
      props.onClose();
    },
  });
  const checklistStatus = useQuery(getChecklist);

  const Finished = () => {
    ackChecklist();
    return (
      <div className="pb-2">
        <Loading className="mb-2 text-center" />
      </div>
    );
  };

  const steps = [
    {
      name: "Work from home",
      component: <Checklist items={WorkFromHomeChecklist} />,
    },
    {
      name: "Seated ergonomics",
      component: <Checklist items={ErgonomicChecklist} />,
    },
    {
      name: "Standing ergonomics",
      component: <Checklist items={StandingChecklist} />,
    },
    { 
      name: "Finish",
      component: <Finished />
    },
  ];

  return (
    <Modal
      id="onboard-checklist"
      className="modal-lg"
      backdrop={true}
      isOpen={props.isOpen}
      toggle={props.onClose}
    >
      <ModalHeader tag={'div'} toggle={props.onClose}>
        <h2>Welcome to desk.coach!</h2>
      </ModalHeader>
      <ModalBody className="pt-0">
        <StepZilla
          steps={steps}
          nextButtonCls="btn btn-prev btn-default pull-right float-right"
          backButtonCls="btn btn-next btn-default pull-left"
          nextTextOnFinalActionStep="Done"
          showSteps={false}
        />
      </ModalBody>
    </Modal>
  );
};

type ChecklistItem = {
  title: string;
  items: string[];
}
interface ChecklistProps {
  items: ChecklistItem[];
}
const Checklist = (props: ChecklistProps) => {
  return (
    <ul className="list-group text-sm pb-4">
      {props.items.map((li) => {
        return (
          <div key={li.title}>
            <li className="list-group-item active h3 mb-0">
              {li.title}
            </li>
            {li.items.map((item, i) => {
              return (
                <li key={i} className="list-group-item py-2 px-3" >
                  <ReactMarkdown components={{ p: "span" }}>
                    {item}
                  </ReactMarkdown>
                </li>
              );
            })}
          </div>
        );
      })}
    </ul>
  );
};

export { OnboardChecklist };
