import { gql } from "@apollo/client";

export const updateTaskById = gql`
  mutation updateTaskById($_id: MongoID!, $record: UpdateByIdTaskInput!) {
    task_UpdateById(_id: $_id, record: $record) {
      record {
        _id
      }
    }
  }
`;
