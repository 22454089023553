import { Spinner } from "reactstrap";

interface LoadingSpinnerProps {
  className?: string;
  size?: string;
}

const Loading = (props: LoadingSpinnerProps) => {
  return (
    <div className={`${props?.className} d-flex justify-content-center text-center align-items-center`}>
      <Spinner animation="border" variant="primary" size={props?.size} />
    </div>
  );
};

export default Loading;