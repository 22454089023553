import { gql, useQuery } from "@apollo/client";

export default function useTaskSequences() {
  return useQuery(gql`
    query getTaskSequences {
      task_sequences {
        name
        _id
        include_in_rotation
        image
        sequence {
          order
          task {
            _id
            action_url
            category
            description
            images
            movement_video
            name
            organisation_id
            video
            instructions {
              title
              text
            }
          }
        }
      }
    }
  `);
}

