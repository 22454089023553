import { gql } from "@apollo/client";

// TODO: move this to hook 'useEmpowerMeSchedule'
export const getEmpowerMeTaskSchedule = gql`
  query {
    me {
      empower_task_schedule {
        scheduled_tasks {
          _id
          start_date
          last_assigned_at
          recurrence_rule
          empower_me_task {
            _id
            name
            description
            task_category {
              description
              image
            }
          }
        }
      }
    }
  }
`;

export const getEmpowerMeTasks = gql`
  query getEmpowerMeTasks {
    empower_me_tasks {
      _id
      name
      description
      task_category {
        description
        image
      }
    }
  }
`;
