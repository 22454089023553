import paginationFactory from "react-bootstrap-table2-paginator";

const PaginationMenu = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length">
      <label>
        Show
        {
          <select
            name="pagination-select"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) =>
              onSizePerPageChange(e.target.value as unknown as number)
            }
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

export default PaginationMenu;
