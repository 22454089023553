import React from "react";
const Legends = ({
  className = "my-3",
  items = [],
  onClick = () => null,
  styles,
}) => {
  return (
    <aside
      className={`d-block w-100 flex-grow-1 ${className}`}
      style={{ ...styles }}
    >
      <ul className="list-group list-group-horizontal list-group-flush small justify-content-around w-100">
        {items.map((item, i) => (
          <React.Fragment key={`${item.name}_${i}`}>
            <li
              className="list-group-item p-2 text-truncate border-0 bg-transparent"
              id={`legend_${item.id}`}
              style={{
                cursor: onClick || item.onClick ? "pointer" : "default",
              }}
            >
              <i
                className="fas fa-circle shadow mr-1"
                style={{ color: item.color, opacity: item?.opacity }}
              ></i>
              {item.name}
            </li>
          </React.Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default Legends;
