import { useField } from "formik";
import { Input } from "reactstrap";
import FormError from "./FormError";

type Props = {
  disabled?: boolean;
  name: string;
  placeholder: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  className?: string;
};

export default function TextFormInput(props: Props) {
  const [field, meta] = useField(props);

  return (
    <div className={`flex flex-col ${props?.className}`}>
      {props.label && (
        <label className={`whitespace-nowrap	pb-1`}>
          {props.label} {props.required && "*"}
        </label>
      )}
      <Input
        disabled={props.disabled}
        {...field}
        placeholder={props.placeholder}
        status={meta.error && meta.touched ? "error" : ""}
        defaultValue={props.defaultValue}
      />
      <FormError>{meta.touched && meta.error}</FormError>
    </div>
  );
}
