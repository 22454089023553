import React from "react";
import { Group } from "@visx/group";
import { AreaClosed } from "@visx/shape";
import { LinearGradient } from "@visx/gradient";
import { curveMonotoneX } from "@visx/curve";
import { Axis } from "@visx/axis";
import { LinePath } from "@visx/shape";
import { GridRows, GridColumns } from "@visx/grid";
import AreasSeparation from "./AreasSeparation";
import { performance_colours } from "./utils";

const AreaSeparations = ({ data, getX, getY, innerHeight, xScale, yScale }) => (
  <>
    <AreasSeparation
      id={`optimal__low-count`}
      data={data}
      x={(d) => xScale(getX(d)) ?? 0}
      y0={(d) => yScale(getY(d)) ?? 0}
      y1={(d) => yScale(4) ?? 0}
      separation={(d) => yScale(4) ?? 0}
      clipAboveTo={yScale(6)}
      clipBelowTo={innerHeight}
      curve={curveMonotoneX}
      belowAreaProps={{
        fill: performance_colours["Low Count"],
        fillOpacity: 1,
      }}
      aboveAreaProps={{
        fill: performance_colours["Optimal"],
        fillOpacity: 1,
      }}
    />
    <AreasSeparation
      id={`overwork__burnout`}
      data={data}
      x={(d) => xScale(getX(d)) ?? 0}
      y0={(d) => yScale(getY(d)) ?? 0}
      y1={(d) => yScale(6) ?? 0}
      separation={(d) => yScale(9)}
      clipAboveTo={0}
      clipBelowTo={yScale(6)}
      curve={curveMonotoneX}
      belowAreaProps={{
        fill: performance_colours["Overwork"],
        fillOpacity: 0.7,
      }}
      aboveAreaProps={{
        fill: performance_colours["Burnout"],
        fillOpacity: 1,
      }}
    />
  </>
);

const DataPoints = ({
  data,
  getX,
  getY,
  xScale,
  yScale,
  handleTooltip,
  hideTooltip,
}) => {
  const highest_value =
    data?.find(
      (d, _, all) =>
        d.count === all.reduce((curr, next) => Math.max(curr, next?.count), [])
    )?.count || 0;

  return (
    <>
      {data.map((d, i) => (
        <React.Fragment key={i} >
          <circle
            key={`${d.date + getY(d)}_${i}`}
            className="data-point slide-bottom "
            cx={xScale(getX(d))}
            cy={yScale(getY(d))}
            r={getY(d) === highest_value ? 10 : 6}
            fill={
              getY(d) > 11
                ? "var(--danger)"
                : getY(d) < 4
                ? "var(--warning)"
                : "#0E3EDA"
            }
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />
        </React.Fragment>
      ))}
    </>
  );
};

const Axes = ({ axesProps: { xAxisProperties, yAxisProperties } }) => {
  return (
    <>
      <Axis {...xAxisProperties} />
      <Axis {...yAxisProperties} />
    </>
  );
};

const AreaChart = ({
  data,
  areaSeparationsComponent = null,
  getX,
  getY,
  handleTooltip,
  hideTooltip,
  innerHeight,
  innerWidth,
  inspectMode,
  hideDataPoints = false,
  hideGridsRow = false,
  hideGridsColumn = false,
  margin,
  xScale,
  yScale,
  axesProps = {},
  tick_value,
  top,
  left,
  children,
}) => {
  return (
    <Group left={left || margin.left} top={top || margin.top}>
      <LinearGradient
        from="var(--gray)"
        to="#ffff"
        id="white-to-gray"
        toOpacity={0.05}
      />

      {!inspectMode && (
        <LinePath
          className="dissolve"
          data={data}
          x={(d) => xScale(getX(d)) ?? 0}
          y={(d) => yScale(getY(d)) ?? 0}
          stroke={"var(--dark)"}
          strokeWidth={1}
          curve={curveMonotoneX}
        />
      )}

      {inspectMode ? (
        areaSeparationsComponent ? (
          areaSeparationsComponent
        ) : (
          <AreaSeparations
            {...{ data, getX, getY, innerHeight, xScale, yScale }}
          />
        )
      ) : null}
      {!inspectMode && (
        <AreaClosed
          data={data}
          x={(d) => xScale(getX(d)) || 0}
          y={(d) => yScale(getY(d)) || 0}
          yScale={yScale}
          strokeWidth={1}
          stroke={"transparent"}
          fill="url('#white-to-gray')"
          fillOpacity=".3"
          curve={curveMonotoneX}
        />
      )}

      {!hideDataPoints && (
        <DataPoints
          {...{ data, getX, getY, xScale, yScale, handleTooltip, hideTooltip }}
        />
      )}

      {!hideGridsRow && (
        <GridRows
          scale={yScale}
          left={30}
          width={innerWidth}
          strokeDasharray="2,3"
          stroke={"var(--gray)"}
          strokeOpacity={1}
          tickValues={yScale.ticks().filter((x) => x % 2 === 0)}
        />
      )}

      {!hideGridsColumn && (
        <GridColumns
          scale={xScale}
          width={innerWidth}
          height={innerHeight}
          stroke="var(--gray)"
          strokeDasharray="2,3"
          tickValues={tick_value}
        />
      )}

      {Object.keys(axesProps).length > 0 && <Axes {...{ axesProps }} />}

      {children}
    </Group>
  );
};

export { AreaChart, AreaSeparations };
