import { gql, useQuery } from "@apollo/client";

export function useWorkHealthTaskSequence(sequenceId: string) {
  return useQuery(
    gql`
      query getWorkHealthTaskSequenceById($_id: MongoID!) {
        workhealth_sequence(_id: $_id) {
          _id
          name
          include_in_rotation
          image
          sequence {
            order
            workhealth_task {
              _id
              action_url
              category
              description
              images
              name
              organisation_id
              instructions {
                title
                text
              }
            }
          }
        }
      }
    `,
    {
      variables: { _id: sequenceId },
    }
  );
}