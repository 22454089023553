import { useRef } from "react";
import { useFormikContext } from "formik";
import { useLazyQuery } from "@apollo/client";
import { Input } from "reactstrap";
import toast from "react-hot-toast";
import FormError from "./FormError";
import { getImageUploadURL } from "../../../graphql/queries/getImageUploadURL";
import Loading from "../../Loading";
import { CloudflareSignedFile } from "../../../interfaces/CloudflareSignedFile";
import styles from "../../../assets/css/EditTaskModal.module.css";

type CloudflareImageFormInputProps = {
  index: number;
  placeholder: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
  className?: string;
  disabled?: boolean;
  onImageSelect: (image: CloudflareSignedFile, index: number) => void;
};

const imageTypesAllowed =
  "image/png,image/gif,image/jpeg,image/jpg,image/webp,image/svg+xml";

export default function CloudFlareImageFormInput(
  props: CloudflareImageFormInputProps
) {
  const formikContext = useFormikContext();

  // Query for fetching signed URL to upload image
  const [
    getSignedUrl,
    { loading: fetchingSignedUrl, error: fetchSignedUrlError },
  ] = useLazyQuery(getImageUploadURL, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data?.getImageUploadURL?.id) {
        props.onImageSelect(
          {
            cloudflareId: data.getImageUploadURL.id,
            uploadUrl: data.getImageUploadURL.uploadURL,
            file: selectedFile.current,
          },
          props.index
        );

        // NOTE: Possible issue: User selects image, then immediately submits form
        //      before these field values below have not finished setting. 
        // Assign field value to the cloudflare id to be associated with upload
        formikContext.setFieldValue(
          `images.${props.index}`,
          data.getImageUploadURL.id
        );
        // Initialise instructions to empty
        formikContext.setFieldValue(`instructions.${props.index}`, {
          text: "",
          title: "",
        });
    
      } else {
        toast.error(
          "Failed to upload image. If the problem persists, you may need to refresh your browser."
        );
      }
    },
    onError() {
      toast.error(
        "Something went wrong whilst uploading image, please try again. If the issue persists, please contact a System Administrator"
      );
    },
  });

  const selectedFile = useRef<File | null>(null);

  const setFile = (file: File) => {
    selectedFile.current = file;
  };
  const deselectFile = () => {
    selectedFile.current = null;
  };

  const handleFileChange = (e) => {
    const file: File = e.target.files[0];
    if (file) {
      // Ensure file selected is correct format
      const fileExt = file.name.match(/\.([^.]+)$/)?.[1].toLowerCase();
      if (fileExt && !imageTypesAllowed.includes(fileExt)) {
        toast.error("This file format is not supported");
        return;
      }
      // Setting the file must be done before fetching signedUrl as we rely on the selected file on fetch completion
      setFile(file);

      // Begin fetching for a signed URL for image upload
      getSignedUrl();
    } else {
      // Sets the selection to be null if not already
      deselectFile();

      // Clear image's instructions when file has been deselected
      formikContext.setFieldValue(`instructions.${props.index}`, undefined);
    }
  };

  
  return (
    <div className={`flex flex-col ${props.className} px-2`}>
      <>
        {/* Show Loading spinner while signed URLs are being fetched */}
        {fetchingSignedUrl ? (
          <Loading />
        ) : (
          <>
            {/* File Selector */}
            <label
              htmlFor={"image-select"}
              className={`form-control mb-3 ${styles.fileSelector}`}
            >
              <span>{props.placeholder}</span>
              <i className="fas fa-upload fa-2xl"></i>
            </label>
            <Input
              id={"image-select"}
              type="file"
              accept={imageTypesAllowed}
              disabled={props.disabled}
              defaultValue={props.defaultValue}
              onChange={handleFileChange}
            />
          </>
        )}
        {fetchSignedUrlError && (
          <FormError>Something went wrong whilst uploading image.</FormError>
        )}
      </>
    </div>
  );
}
