import { SortOrder } from "react-bootstrap-table-next";
import { User } from "../../interfaces/models/User";

export enum UsageIndicator {
  Checklist,
  LowTouch,
  Burnout
}

export enum DisplayMode {
  Weekly,
  Monthly
}

export interface SortMode {
  field: string,
  order: SortOrder
}

export type TableMode = {
  indicator: UsageIndicator,
  title: string,
  description?: string;
  data: User[];
}