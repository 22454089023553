const performance_colours = {
  Burnout: "var(--danger)",
  Overwork: "var(--danger)",
  Optimal: "var(--primary)",
  "Low Count": "var(--warning)",
};
const performance_indicator = {
  Burnout: 11,
  Overwork: 9,
  Optimal: 6,
  "Low Count": 4,
};

export { performance_colours, performance_indicator };
