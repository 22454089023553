import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { NavLink } from "react-router-dom";

interface BreadcrumbsProps {
  crumbs: Array<{
    name: string;
    path: string;
  }>;
}
export default function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
  if (!crumbs || crumbs.length <= 0) {
    return null;
  }

  return (
    <div className="w-100">
      <Breadcrumb>
        {crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <BreadcrumbItem key={name + key + 1} active>
              {key === 0 ? "Home" : name}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={name + key}>
              <NavLink to={path}>{key === 0 ? "Home" : name}</NavLink>
            </BreadcrumbItem>
          )
        )}
      </Breadcrumb>
    </div>
  );
}
