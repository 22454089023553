import { useMemo } from "react";
import useWorkHealthSequences from "../../hooks/useWorkHealthSequences";
import { useRouteMatch } from "react-router-dom";
import { TaskSequences } from "../../components/Tasks/TaskSequences";
import { sortSequencesByName } from "../../helper/sortSequencesByName";

export default function WorkHealthCoachPage() {
  const match = useRouteMatch();
  const { data, loading, error } = useWorkHealthSequences();
  const sequences = useMemo(() => {
    if (data?.workhealth_sequences) {
      return sortSequencesByName(data.workhealth_sequences);
    }

  }, [data]);

  return (
    <TaskSequences
      loading={loading}
      error={error}
      sequences={sequences}
      match={match}
    />
  );
}

export { WorkHealthCoachPage as WorkHealth };
