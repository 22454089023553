import { PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import DashboardRoutes from "./components/Routes/DashboardRoutes";
import SecureApp from "./components/SecureApp";
import { azureConfig } from "./config/auth/azure.config";
import VideoLayout from "./layouts/VideoLayout";
import LoginPage from "./pages/LoginPage";
import { Toaster } from "react-hot-toast";

const msalInstance = new PublicClientApplication(azureConfig);

export default function App() {
  return (
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <SecureApp>
            <Switch>
              <Route
                path="/video/:id"
                render={(props) => <VideoLayout {...props} />}
              />
              <DashboardRoutes />
            </Switch>
            <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={8}
              toastOptions={{
                duration: 5000,
                style: {
                  background: "#363636",
                  color: "#fff",
                },
                success: {
                  duration: 3000,
                  theme: {
                    primary: "green",
                    secondary: "black",
                  },
                },
              }}
            />
          </SecureApp>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Switch>
            <Route
              exact
              path="/video/:id"
              render={(props) => <VideoLayout {...props} />}
            />
            <Route
              path="/login"
              render={(props) => {
                //@ts-ignore
                return <LoginPage {...props} />;
              }}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </BrowserRouter>
  );
}
