import React from "react";
import { Area } from "@visx/shape";
const AreasSeparation = ({
  aboveAreaProps,
  belowAreaProps,
  curve,
  clipAboveTo,
  clipBelowTo,
  data,
  id,
  separation,
  x,
  y0,
  y1,
}) => {
  return (
    <>
      <g>
        <Area curve={curve} data={data} x={x} y1={separation}>
          {({ path }) => {
            let belowPath = null;
            let abovePath = null;

            if (typeof clipBelowTo === "number")
              belowPath = path.y0(clipBelowTo)(data);
            else belowPath = path.y0(clipBelowTo)(data);
            if (typeof clipAboveTo === "number")
              abovePath = path.y0(clipAboveTo)(data);
            else abovePath = path.y0(clipAboveTo)(data);

            return (
              <g>
                <clipPath id={`threshold-clip-below-${id}`}>
                  <path d={belowPath || ""} />
                </clipPath>
                <clipPath id={`threshold-clip-above-${id}`}>
                  <path d={abovePath || ""} />
                </clipPath>
              </g>
            );
          }}
        </Area>
        <Area
          curve={curve}
          data={data}
          x={x}
          y0={y0}
          y1={y1}
          strokeWidth={0}
          clipPath={`url(#threshold-clip-below-${id})`}
          {...belowAreaProps}
        />
        <Area
          curve={curve}
          data={data}
          x={x}
          y0={y0}
          y1={y1}
          strokeWidth={0}
          clipPath={`url(#threshold-clip-above-${id})`}
          {...aboveAreaProps}
        />
      </g>
    </>
  );
};

export default AreasSeparation;
