import heartSvg from "../../assets/img/icons/common/heart.svg";
import cancerSvg from "../../assets/img/icons/common/cancer.svg";
import healthyEatingSvg from "../../assets/img/icons/common/healthy-eating.svg";
import psychologySvg from "../../assets/img/icons/common/psychology.svg";
import diabetesSvg from "../../assets/img/icons/common/diabetes.svg";
import jointPainSvg from "../../assets/img/icons/common/joint-pain.svg";
import lungsSvg from "../../assets/img/icons/common/lungs.svg";
import muscleSvg from "../../assets/img/icons/common/muscle-spasm.svg";

interface Link {
  text: string;
  url: string;
}

export interface Section {
  title: string;
  link: Link;
  description?: string;
  icon: string;
}

export const healthHubInformation: Section[] = [
  {
    title: "Healthy Eating Guidelines",
    link: {
      text: "The Australian Dietary Guidelines | Australian Government Department of Health and Aged Care",
      url: "https://www.health.gov.au/resources/publications/the-australian-dietary-guidelines",
    },
    icon: healthyEatingSvg,
  },
  {
    title: "Cardiovascular Disease (CVD)",
    link: {
      text: "Heart, stroke & vascular diseases Overview - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/reports-data/health-conditions-disability-deaths/heart-stroke-vascular-diseases/overview",
    },
    icon: heartSvg,
    description:
      "Regular physical activity is one of the best things you can do for your heart health...",
  },
  {
    title: "Cancer",
    link: {
      text: "Cancer Overview - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/reports-data/health-conditions-disability-deaths/cancer/overview",
    },
    icon: cancerSvg,
    description: "At least one in three cancer cases could be prevented...",
  },
  {
    title: "Type 2 Diabetes Mellitus (T2DM)",
    link: {
      text: "Diabetes Overview - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/reports-data/health-conditions-disability-deaths/diabetes/overview",
    },
    description:
      "Evidence, including large-scale randomized control trials, shows type 2 diabetes can be prevented...",
    icon: diabetesSvg,
  },
  {
    title: "Mental health",
    link: {
      text: "Mental health services in Australia, Summary of Mental health services in Australia - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/suicide-self-harm-monitoring/research-information/crisis-support",
    },
    description:
      "Several reviews have pooled findings from multiple studies and found that computer-assisted therapy can be effective in treating depression in adults and adolescents...",
    icon: psychologySvg,
  },
  {
    title: "Musculoskeletal",
    link: {
      text: "Back problems, About - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/reports/chronic-musculoskeletal-conditions/musculoskeletal-conditions/contents/about",
    },
    description:
      "Regular exercise is key to a healthy back. It will improve your posture and increase muscle support of the spine...",
    icon: muscleSvg,
  },
  {
    title: "Arthritis",
    link: {
      text: "Arthritis, Arthritis - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/reports/chronic-musculoskeletal-conditions/musculoskeletal-conditions/contents/arthritis",
    },
    description:
      "Research has found that regular exercise is one of the most effective treatments for arthritis. It can help to improve mobility and flexibility of joints, muscle strength, posture, and balance...",
    icon: jointPainSvg,
  },
  {
    title: "Chronic Obstructive Pulmonary disease (COPD)",
    link: {
      text: "Chronic obstructive pulmonary disease (COPD), About - Australian Institute of Health and Welfare (aihw.gov.au)",
      url: "https://www.aihw.gov.au/reports/chronic-respiratory-conditions/copd/contents/about",
    },
    icon: lungsSvg,
  },
];
