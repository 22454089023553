import { Col, Row } from "react-bootstrap";
import styles from "../../../assets/css/EditTaskModal.module.css";

interface Props {
  fileName: string;
  fileUrl: string;
  deselectMedia: () => void;
}

export default function VideoSelectedCard(props: Props) {

  return (
    <div className="d-flex justify-content-between mb-3">
      <div className="d-flex">
        <div className="ml-2" style={{ maxWidth: "28rem" }}>
          <p>{props.fileName}</p>
        </div>
      </div>
      <Row>
        <Col>
          <i
            className={`fas fa-trash-alt ${styles.deleteIcon}`}
            onClick={props.deselectMedia}
          />
        </Col>
      </Row>
    </div>
  );
}
