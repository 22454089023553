import { Card, CardBody } from "reactstrap";
import ChallengeImg from "../../assets/img/media/challenge.jpg";
import topic9 from "../../assets/img/media/topic-9.jpg";

interface Topics  {
    image: string;  
    name: string;
    description : string;
    startDate : Date;
    endDate : Date;
}

export default function WeeklyChallengeCard() {

  const defaultTopic : Topics = {
    image : ChallengeImg,
    name : "Teamwork makes the dream work.",
    description : "Knowing you are seen and authentically appreciated is key to feeling included.",
    startDate : new Date("2024-10-28"),
    endDate : new Date("2024-11-03"),
  }

  const topics : Topics[] = [
    {
      image : ChallengeImg,
      name : "Safety is everyone's business.",
      description : "Social connections are important for building a healthy culture at work, creating empathy and support.",
      startDate : new Date("2024-09-30"),
      endDate : new Date("2024-10-06"),
    },
    {
      image : ChallengeImg,
      name : "Mental Health Minute.",
      description : "60 seconds of movement, meditation, breathwork, gratitude practice etc help to foster an improved mood state and better wellbeing.",
      startDate : new Date("2024-10-07"),
      endDate : new Date("2024-10-13"),
    },
    {
      image : ChallengeImg,
      name : "Prevention is better than cure.",
      description : "1% Improvements each day accumulates to compound interest on your health.",
      startDate : new Date("2024-10-14"),
      endDate : new Date("2024-10-20"),
    },
    {
      image : ChallengeImg,
      name : "Your body counts too!",
      description : "Using large muscle mass regularly helps to prevent injury, regulate blood sugar levels and improve mood.",
      startDate : new Date("2024-10-21"),
      endDate : new Date("2024-10-27"),
    },
    {
      image : ChallengeImg,
      name : "Teamwork makes the dream work.",
      description : "Knowing you are seen and authentically appreciated is key to feeling included.",
      startDate : new Date("2024-10-28"),
      endDate : new Date("2024-11-03"),
    },
    {
      image : topic9,
      name : "Movember is our time to unite.",
      description : "Take on mental health, suicide, prostate cancer, and testicular cancer. Come along for the ride, it only makes us stronger!",
      startDate : new Date("2024-11-04"),
      endDate : new Date("2024-11-10"),
    },
    {
      image : topic9,
      name : "World Kindness Day -13th November!",
      description : "A day to remind us of the importance of the essential role kindness plays in fostering healthy & supportive communities.",
      startDate : new Date("2024-11-11"),
      endDate : new Date("2024-11-17"),
    },
    {
      image : topic9,
      name : "Movember is our time to unite.",
      description : "Move for Mental health. It only takes a moment and your mood will boost!",
      startDate : new Date("2024-11-18"),
      endDate : new Date("2024-11-24"),
    },
    {
      image : topic9,
      name : "Movember is our time to unite.",
      description : "Celebrate the wonderful men in your life. Acknowledging effort and good intentions sends a wonderful message and can positively influence your environment.",
      startDate : new Date("2024-11-25"),
      endDate : new Date("2024-12-01"),
    }
  ];

  // const currentWeek = getCurrentWeek(new Date()) || 1;
  // const currentTopic = topics[currentWeek - 1];

  const dateToday = new Date();
  let currentTopic = topics.find( u => dateToday >= u.startDate && dateToday <=  u.endDate );
  if(currentTopic == null){
    currentTopic = defaultTopic;
  }

  return (
      <Card className="h-100">
        <CardBody>
          <h3 style={{ marginBottom: "1rem" }}>Topic</h3>
          <div className="d-flex flex-column justify-content-center items-center mt-2">
            <img
              style={{
                width: "100%",
                borderRadius: "0.375rem",
                marginBottom: "1rem",
              }}
              src={currentTopic.image}
              alt={currentTopic.name}
            />
          </div>
          <h4 style={{ marginBottom: "1rem" }}>{currentTopic.name}</h4>
          <p>{currentTopic.description}</p>
        </CardBody>
      </Card>
    );
}
