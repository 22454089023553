import Breadcrumbs from "./Breadcrumbs";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import Category from "../../interfaces/models/Category";

type Props = {
  categories?: Array<Category>;
};

export default function CategoryBreadcrumbs({ categories }: Props) {
  const location = useLocation();
  const breadcrumbs = useMemo<Array<{ path: string; name: string }>>(() => {
    const breadcrumbs = [];
    const pathnames = location.pathname.split("/");
    for (let i = 2; i < pathnames.length; i++) {
      breadcrumbs.push({
        name: pathnames[i],
        path: pathnames.slice(0, i + 1).join("/"),
      });
    }
    return breadcrumbs;
  }, [location.pathname]);

  return (
    <Breadcrumbs
      crumbs={breadcrumbs?.map((bc) => {
        const categoryName = categories?.find(
          (cat) => cat._id === bc.name
        )?.description;
        return {
          name: categoryName ?? bc.name,
          path: bc.path,
        };
      })}
    />
  );
}
