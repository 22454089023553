import { gql, useQuery } from "@apollo/client";
import moment from "moment";
// import UserDashboard from "../interfaces/hookModels/UserDashboard"

export default function useDashboardTasks() {
  
  const midnightToday = moment().set({'h': 0, 'm': 0, 's': 0});
  const midnightTomorrow = moment().set({'h': 0, 'm': 0, 's': 0}).add(1, 'd');

 //still checking
  // type GetUserVariables = {
  //   from: string;
  //   to: string;
  // };

  return useQuery(
    gql`
      query getUser($from: Date! $to: Date!) {
        me {
          task_sequence {
            _id
            sequence {
              order
              delay
              task_id
              task {
                _id
                name
                description
              }
            }
          }
          workhealth_sequence {
            _id
            sequence {
              order
              delay
              workhealth_task {
                _id
                name
                description
                action_url
              }
            }
          }
          user_license {
            products
          }
        }
        task_assignments(filter: { from: $from, to: $to  }) {
          _id
          assigned_at
          user_acknowledged
          user_acknowledged_at
          task_complete
          task {
            _id
            name
          }
        }
        workhealth_task_assignments(filter: { from: $from, to: $to }) {
          _id
          assigned_at
          user_acknowledged
          user_acknowledged_at
          task_complete
          workhealth_task {
            _id
            name
          }
        }
      }
    `,
    {
      variables: { from: midnightToday.format("YYYY-MM-DDTHH:mm:ssZ"), to: midnightTomorrow.format("YYYY-MM-DDTHH:mm:ssZ") },
      pollInterval: 300000, //poll to refresh the dashboard every 5 minutes
    }
  );
}
