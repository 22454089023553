import { useState } from "react";
import * as Tus from "tus-js-client";

interface UploadVideoOptions {
  onError?: (err: Error | Tus.DetailedError) => void;
  onSuccess?: () => void;
}

export default function UseVideoUpload() {
  const [uploadingVideo, setUploadingVideo] = useState<boolean>(false);
  const [uploadingVideoProgress, setUploadingVideoProgress] = useState<
    number | null
  >(null);

  const uploadVideo = async (
    signedUrl: string,
    videoFile: File,
    options?: UploadVideoOptions
  ) => {
    setUploadingVideo(true);
    const upload = new Tus.Upload(videoFile, {
      chunkSize: 5242880,
      uploadUrl: signedUrl, // Use the custom upload URL
      onError: function (error) {
        setUploadingVideo(false);
        setUploadingVideoProgress(null);
        options?.onError && options?.onError(error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = (bytesUploaded / bytesTotal) * 100;
        setUploadingVideoProgress(percentage);
      },
      onSuccess: function () {
        setUploadingVideo(false);
        setUploadingVideoProgress(null);
        options?.onSuccess && options?.onSuccess();
      },
    });

    upload.start(); // Start the upload
  };

  return { uploadVideo, uploadingVideo, uploadingVideoProgress };
}
