import { Card, CardBody } from "reactstrap";
import topic1 from "../../assets/img/media/topic-1.jpg";
import topic2 from "../../assets/img/media/topic-2.jpg";
import topic3 from "../../assets/img/media/topic-3.jpg";
import topic4 from "../../assets/img/media/topic-4.jpg";
import topic5 from "../../assets/img/media/topic-5.jpg";
import topic6 from "../../assets/img/media/topic-6.jpg";
import topic7 from "../../assets/img/media/topic-7.jpg";
import topic8 from "../../assets/img/media/topic-8.jpg";

interface Challenges  {
  image: string;  
  name: string;  
  description: string;
  startDate : Date;
  endDate : Date;
}

export default function WeeklyChallengeCard() {

  const defaultChallenge : Challenges = {
    image : topic5,
    name : "Give gratitude",
    description : "Are there team members that would benefit from hearing your gratitude?  Who will you give gratitude to today?",
    startDate : new Date("2024-10-28"),
    endDate : new Date("2024-11-03"),
  }

  const challenges : Challenges[] = [
    {
      image : topic1,
      name : "Coffee connect with a colleague.",
      description : "Will you include a different colleague in a chat? Who can you learn something new about today?",
      startDate : new Date("2024-09-30"),
      endDate : new Date("2024-10-06"),
    },
    {
      image : topic2,
      name : "1 minute ME",
      description : "Will you take a minute each day for yourself?  Will you spend 1 minute of your day for you?",
      startDate : new Date("2024-10-07"),
      endDate : new Date("2024-10-13"),
    },
    {
      image : topic3,
      name : "Push for prevention!",
      description : "Will you try some push ups (on the wall, table, or floor) each day? Will you try to do one more each day?",
      startDate : new Date("2024-10-14"),
      endDate : new Date("2024-10-20"),
    },
    {
      image : topic4,
      name : "Massive muscle minute!",
      description : "No, we aren't asking you to be Arnie!  Will you do as many squats as you can in a minute each day?",
      startDate : new Date("2024-10-21"),
      endDate : new Date("2024-10-27"),
    },
    {
      image : topic5,
      name : "Give gratitude",
      description : "Are there team members that would benefit from hearing your gratitude?  Who will you give gratitude to today?",
      startDate : new Date("2024-10-28"),
      endDate : new Date("2024-11-03"),
    },
    {
      image : topic6,
      name : "Team member timeout!",
      description : "Is there a new or unfamiliar team member you can spend a few minutes with to learn more about them?",
      startDate : new Date("2024-11-04"),
      endDate : new Date("2024-11-10"),
    },
    {
      image : topic7,
      name : "Transformative gestures.",
      description : "Is there a way to express some kindness to someone in your team? Acts of kindness produces oxytocin which assists with optimism, reducing blood pressure and improving overall health.",
      startDate : new Date("2024-11-11"),
      endDate : new Date("2024-11-17"),
    },
    {
      image : topic8,
      name : "Boost your Mood!",
      description : "Will you take a 15 minute #deskbreak for movement? It just takes a moment to shift your mood and change your day! Perhaps a mate can join you on a quick walk around the block?",
      startDate : new Date("2024-11-18"),
      endDate : new Date("2024-11-24"),
    },
    {
      image : topic6,
      name : "Celebrate wonderful men!",
      description : "Will you take a minute each day to celebrate a wonderful man in your life? Authentically acknowledging those around you has hugely beneficial impacts on a healthy culture.",
      startDate : new Date("2024-11-25"),
      endDate : new Date("2024-12-01"),
    }
  ];

  // const currentWeek = getCurrentWeek(new Date()) || 1;
  // const currentChallenge = challenges[currentWeek - 1];
  const dateToday = new Date();
  let currentChallenge = challenges.find( u => dateToday >= u.startDate && dateToday <=  u.endDate );
  if(currentChallenge == null){
    currentChallenge = defaultChallenge;
  }


  return (
    <Card className="h-100">
      <CardBody>
        <h3 style={{ marginBottom: "1rem" }}>Challenge</h3>
        <div className="d-flex flex-column justify-content-center items-center mt-2">
          <img
            style={{
              width: "100%",
              borderRadius: "0.375rem",
              marginBottom: "1rem",
            }}
            src={currentChallenge.image}
            alt={currentChallenge.name}
          />
        </div>
        <h4 style={{ marginBottom: "1rem" }}>{currentChallenge.name}</h4>
        <p>{currentChallenge.description}</p>
      </CardBody>
    </Card>
  );
}
