import * as Yup from "yup";

const MIN_NAME_LENGTH: number = 3;
const MAX_NAME_LENGTH: number = 40;
const MIN_DESCRIPTION_LENGTH: number = 3;
const MAX_DESCRIPTION_LENGTH: number = 250;
const MAX_INSTRUCTION_LENGTH: number = 500;

const editTaskValidator = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(
      MIN_NAME_LENGTH,
      `Name must be at least ${MIN_NAME_LENGTH} characters long`
    )
    .max(
      MAX_NAME_LENGTH,
      `Name must be less than ${MAX_NAME_LENGTH} characters long`
    ),
  description: Yup.string()
    .required("Description is required")
    .min(
      MIN_DESCRIPTION_LENGTH,
      `Description must be at least ${MIN_DESCRIPTION_LENGTH} characters long`
    )
    .max(
      MAX_DESCRIPTION_LENGTH,
      `Description must be less than ${MAX_DESCRIPTION_LENGTH} characters long`
    ),

  images: Yup.array()
    .of(Yup.string())
    .test("at-least-one", "Must have at least 1 image", function (value) {
      return value.some((item) => item !== null && item !== "" && item !== undefined);
    }),

  instructions: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable(),
      text: Yup.string().max(
        MAX_INSTRUCTION_LENGTH,
        `Instructions must be less than ${MAX_INSTRUCTION_LENGTH} characters long`
      ),
    })
  ),
  action_url_link: Yup.string()
    .nullable()
    .matches(
      /((https?):\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/[a-zA-Z0-9#-]*)?(\?[a-zA-Z0-9-_=&]*)?(#?[a-zA-Z0-9]*)?/,
      "Please provide a valid url"
    ),
    action_url_video: Yup.string().nullable(),
});
export default editTaskValidator;
