import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Alert } from "reactstrap";
import Loading from "../../components/Loading";
import { getEmpowerMeTasks } from "../../graphql/empower-me/EmpowerMeQueries";
import { EmpowerTask } from "../../interfaces/models/EmpowerTask";
import { AccordionHeader } from "./Accordion/AccordionHeader";
import { AccordionSelectorField } from "./Accordion/AccordionSelectorField";
import "./EmpowerMePage.scss";

interface TaskSelectionProps {
  select: (task: EmpowerTask) => void;
  currentSelection: EmpowerTask | null;
}

type CategorisedEmpowerMeTasks = {
  physicalTasks: EmpowerTask[];
  nutritionTasks: EmpowerTask[];
  pyschologicalTasks: EmpowerTask[];
  mindfulTasks: EmpowerTask[];
};

const CATEGORY_PHYSICAL = "As a physically proactive person";
const CATEGORY_NUTRITIONAL = "As a nutritionally aware person";
const CATEGORY_PSYCHOLOGICAL = "As a psychologically proactive person";
const CATEGORY_MINDFUL = "As a mindful individual";

export default function EmpowerTaskSelection({
  select,
  currentSelection,
}: TaskSelectionProps) {
  // TODO: the accordion that contains an initially selected task should be open

  const [empowerMeTasks, setEmpowerMeTasks] =
    useState<CategorisedEmpowerMeTasks>({
      physicalTasks: [],
      nutritionTasks: [],
      pyschologicalTasks: [],
      mindfulTasks: [],
    });

  // Populate options with empower me tasks
  const { loading, error } = useQuery(getEmpowerMeTasks, {
    onCompleted(data) {
      let categorisedTasks: CategorisedEmpowerMeTasks = {
        physicalTasks: [],
        nutritionTasks: [],
        pyschologicalTasks: [],
        mindfulTasks: [],
      };

      data?.empower_me_tasks.forEach((task, idx) => {
        switch (task?.task_category.description) {
          case CATEGORY_PHYSICAL:
            categorisedTasks.physicalTasks.push(task);
            break;
          case CATEGORY_NUTRITIONAL:
            categorisedTasks.nutritionTasks.push(task);
            break;
          case CATEGORY_PSYCHOLOGICAL:
            categorisedTasks.pyschologicalTasks.push(task);
            break;
          case CATEGORY_MINDFUL:
            categorisedTasks.mindfulTasks.push(task);
            break;
          default:
            break;
        }
      });
      setEmpowerMeTasks(categorisedTasks);
    },
    // TODO: display error on failure
    onError(error) {
      console.log("Error occurred: ", error);
    },
  });

  if (loading) return <Loading />;
  if (error) {
    return (
      <Alert color="danger">
        Sorry! Something went wrong while retrieving the empower me tasks -
        Please try again later or contact a System Administrator.
      </Alert>
    );
  }

  return (
    <div>
      {/* TODO: add prop `defaultActivityKey="eventKey" to the Accordion component to determine which accordion item should be open by default - use this to open the accordion item containing the pre-selected task (during edit)_  */}
      <Accordion flush alwaysOpen>
        <Accordion.Item eventKey={CATEGORY_PHYSICAL}>
          <AccordionHeader
            title={CATEGORY_PHYSICAL}
            eventKey={CATEGORY_PHYSICAL}
          />
          <Accordion.Body className="pb-2">
            <TaskSelectAccordionOptionsList
              currentSelection={currentSelection}
              onSelect={select}
              options={empowerMeTasks.physicalTasks}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={CATEGORY_NUTRITIONAL}>
          <AccordionHeader
            title={CATEGORY_NUTRITIONAL}
            eventKey={CATEGORY_NUTRITIONAL}
          />
          <Accordion.Body className="pb-2">
            <TaskSelectAccordionOptionsList
              currentSelection={currentSelection}
              onSelect={select}
              options={empowerMeTasks.nutritionTasks}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={CATEGORY_PSYCHOLOGICAL}>
          <AccordionHeader
            title={CATEGORY_PSYCHOLOGICAL}
            eventKey={CATEGORY_PSYCHOLOGICAL}
          />
          <Accordion.Body className="pb-2">
            <TaskSelectAccordionOptionsList
              currentSelection={currentSelection}
              onSelect={select}
              options={empowerMeTasks.pyschologicalTasks}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={CATEGORY_MINDFUL}>
          <AccordionHeader
            title={CATEGORY_MINDFUL}
            eventKey={CATEGORY_MINDFUL}
          />
          <Accordion.Body className="pb-2">
            <TaskSelectAccordionOptionsList
              currentSelection={currentSelection}
              onSelect={select}
              options={empowerMeTasks.mindfulTasks}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

interface OptionsProps {
  options: EmpowerTask[];
  onSelect: (task: EmpowerTask) => void;
  currentSelection: EmpowerTask | null;
}
function TaskSelectAccordionOptionsList(props: OptionsProps) {
  return (
    <Container fluid>
      <Row className="mt-2">
        {props.options.map((empowerTask, idx) => {
          return (
            <Col key={idx} xs={12} md={6} xl={4} className="pt-3">
              <AccordionSelectorField
                key={idx}
                empowerTask={empowerTask}
                isSelected={props.currentSelection?._id === empowerTask._id}
                onSelect={props.onSelect}
                name={"taskId"}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
