import { ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

export default function FormError({ children }: Props) {
  return (
    <div className="ml-2 text-sm text-red h-5" style={{ minHeight: "1.3rem" }}>
      {children ?? ""}
    </div>
  );
}
