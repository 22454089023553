import Loading from "../../components/Loading";
import MovementVideoCard from "../../components/Movement/MovementVideoCard";
import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Video from "../../interfaces/models/Video";

interface MovementVideoPageProps {
  videos: Array<Video>;
  onLoaded?: () => void;
}

export default function MovementVideoPage(props: MovementVideoPageProps) {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [loadedThumbnailIds, setLoadedThumbnailIds] = useState<Array<string>>(
    []
  );
  const addLoadedThumbnail = (id: string) => {
    setLoadedThumbnailIds((prev) => {
      return [...prev, id];
    });
  };
  useEffect(() => {
    setIsLoaded(loadedThumbnailIds.length === props.videos.length);
  }, [loadedThumbnailIds, props.videos.length]);

  return (
    <Fragment>
      {!isLoaded && <Loading />}
      <Row className="animated-card" style={{ margin: "1%" }}>
        {props.videos.map((video, idx) => {
          return (
            <Col key={idx} xs={12} lg={4} className="py-2 px-1">
              <MovementVideoCard
                className={!isLoaded && "invisible"}
                video={video}
                onThumbnailLoad={() => addLoadedThumbnail(video._id)}
              />
            </Col>
          );
        })}
      </Row>
    </Fragment>
  );
}
