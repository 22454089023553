const ErgonomicChecklist = [
    {
        "title": "Office Chair",
        "items": [
            "Worker is familiar with all the adjustment mechanisms on their chair",
            "All chair adjustments are in working order",
            "When seated, there is an open angle of 100-120 degrees at the hips (i.e. slightly more than a right angle). This can be achieved by adjusting the seat pan tilt and the leaning back of the backrest",
            "When seated, the knees are level with or lower than the hips",
            "Able to sit right back in the chair so the back support (when adjusted) fits neatly into the hollow of the back and there is approximately 2-3 finger width clearance between the front edge of the seat and the back of the knee (seat depth)",
            "Hips are well supported on the seat (seat width)",
            "Chair is suitable for worker's weight",
            "Chairs do NOT restrict access to the desk (including ability to sit in close to desk, getting up/ down from chair/ turning chair)",
            "Feet flat on ground or footrest without too much pressure from the seat of the chair on the back of the leg"     
        ]
    },
    {
        "title": "Desk",
        "items": [
            "The desk, whether fixed height or adjustable, is between 680mm and 720mm high (from floor to top of desk)",
            "There is a smooth flat surface for the keyboard and the mouse/ other input device to be used on the same level",
            "There is enough space on desk for suitable placement of required equipment and for forearm support (if preferred)",
            "There is sufficient leg room under the desk to stretch the legs",
            "Items are not stored under the desk that encroaches on this space and compromises sitting posture"
        ]
    },
    {
        "title": "Computer Monitor",
        "items": [
            "The monitor is directly in front of the worker and approximately an arm's reach or slightly further away",
            "The top of the screen is at eye level or slightly lower",
            "It is adjusted to a comfortable level of brightness, contrast and font size",
            "The screen is free from glare or reflections from light sources or shadowing onto screen",
            "Worker has clear visibility of screen display (size of font or size display does not need to be changed) so that posture is not compromised",
            "**If one monitor is used more frequently than the other:**",
                " - Place the primary monitor straight ahead to avoid twisting the body or neck to one side to view",
                " - Move the chair when using secondary monitors to avoid bending or twisting to see the displays",
                " - Lower the monitor height so that the top of the monitor is at eye level or lower",
                " - Position the most frequently used displays lower and central on the screen page for a large size monitor with multiple displays on the one screen page",
            "**If using multiple monitors with equal frequency then:**",
                " - Arrange the monitors in an arc",
                " - Position the screens at equal viewing distance from the user",
                " - Position the screens right next to each other"
        ]
    },
    {
        "title": "Keyboard",
        "items": [
            "The keyboard is positioned directly in front of worker at a distance from the edge of the desk that feels comfortable for them. This may be close to the edge of the desk for floating typist or further away for those who prefer forearm support",
            "For floating or touch typist - hands hovering over keyboard, elbows slightly higher than desk, wrists level with forearm",
            "For forearm support - comfortably support most of forearms on desk or armrests",
            "Worker is familiar with short cute keys and uses them to reduce the use of the mouse"
        ]
    },
    {
        "title": "Mouse",
        "items": [
            "Mouse comfortably fits in the hand and works freely",
            "Device is positioned in the close working zone so arm close to side of body",
            "Device is suitable adjusted for speed and accuracy of task",
        ]
    },
    {
        "title": "Laptop",
        "items": [
            "When using a laptop computer for prolonged periods of time, there is use of:",
                " - A full sized external keyboard",
                " - A full sized external pointing device",
                " - A full sized monitor",
                " - A docking station or a laptop stand"
        ]
    },
    {
        "title": "Desktop Items",
        "items": [
            "For periods of data input from hard copy to computer:",
                " - A document holder is used",
                " - Is positioned between the monito and the keyboard or adjacent to and at the same height as the screen",
            "A headset is available for prolonged or frequent phone conversations or if simultaneously keying/writing is required whilst on the phone",
            "There is adequate room on the desktop to accommodate all necessary items",
            "Frequently used items are within comfortable reach"
        ]
    },
    {
        "title": "Seated Posture",
        "items": [
            "When worker is seated at the workstation with their hands on the keyboard, they can achieve the following posture:",
                " - Shoulders relaxed and symmetrical",
                " - Head in midline",
                " - Head not arched backwards or chin not extended forwards",
                " - Elbows close to side of the body",
                " - Forearms can be fully supported if not touch typing",
                " - Wrists in a straight line and not lifted too high",
                " - Hips slightly higher than or level with knees",
                " - Thighs not making contact with under-surface of desk",
                " - Feet flat on the floor or footrest (not dangling)"
        ]
    },
    {
        "title": "Training and postural variation",
        "items": [
            "Workers trained in how to adjust and correctly set up chair, desk and equipment to suit their individual needs",
            "There are opportunities to change working postures as needed"
        ]
    },
    {
        "title": "Hot desking (when applicable)",
        "items": [
            "Provided time, support and supervision to make the above adjustments",
            "Provided with suitable cleaning materials to wipe down commonly used areas"
        ]
    },
    {
        "title": "General lighting",
        "items": [
            "The lighting (including glare, reflections or shadowing) does not interfere with the tasks that are being performed",
            "The lighting is at correct level for visual comfort for the tasks that are being performed"
        ]
    }
]

export { ErgonomicChecklist };