import { gql } from "@apollo/client";

export const ackChecklist = gql`
  mutation ackChecklist {
    me_UpdateChecklist(value: true) {
      checklist
    }
  }
`;

export const getChecklist = gql`
  query {
    me {
      checklist
    }
  }
`;

