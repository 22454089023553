import Legends from "../../components/Legends";

export default function TableLegends() {
  return (
    <Legends
      styles={null}
      className="slide-left"
      items={[
        {
          id: 1,
          name: "Burnout: > 11",
          color: "var(--danger)",
        },
        {
          id: 2,
          name: "Overwork: 9-11",
          color: "var(--danger)",
          opacity: 0.8,
        },
        {
          id: 4,
          name: "Optimal: 5-8",
          color: "var(--primary)",
        },
        {
          id: 5,
          name: "Low Count: <= 4",
          color: "var(--warning)",
        },
      ]}
    />
  );
}
