import { useContext } from "react";
import { Accordion, AccordionContext } from "react-bootstrap";

/**
 * This component is designed for use with react-bootstraps accordion component. Must exist inside of
 * an Accodion parent component.
 */

interface AccordionHeaderProps {
  title: string;
  eventKey: string;
}

export function AccordionHeader({ title, eventKey }: AccordionHeaderProps) {
  const { activeEventKey } = useContext(AccordionContext);
  const isCurrentEventKey =
    activeEventKey?.includes(eventKey) ?? activeEventKey === eventKey;

  return (
    <Accordion.Header>
      <div className="custom-accordion-header">
        <span>{title}</span>
        <i
          className={`fas fa-caret-down fa-lg accordion-caret ${
            isCurrentEventKey && "accordion-caret-active"
          }`}
        ></i>
      </div>
    </Accordion.Header>
  );
}
