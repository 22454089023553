import { Col, Card, CardBody, Row, Button } from "reactstrap";
import { TableMode } from "./UserTableInterfaces";

interface UsageStatCardProps {
  tableMode: TableMode;
  stat: string | number;
  tableOpen: boolean;
  selectTableMode: () => void;
  colour?: string;
}

export default function UsageStatCard({
  tableMode,
  stat,
  tableOpen,
  colour,
  selectTableMode
}: UsageStatCardProps) {
  return (
    <Col lg="4" xl="4" className="mb-sm-4">
      <Card className={`mb-4 mb-xl-0 ${colour} h-100`}>
        <CardBody>
          <Row>
            <Col>
              <h5 className="text-uppercase mb-0 text-white card-title">
                {tableMode.title}
              </h5>
              <span className="h3 font-weight-bold text-white">
                {stat}
              </span>
              <small className="d-block text-white">
                {tableMode?.description}
              </small>
            </Col>
            <Col className="col-auto">
              <Button
                className="btn-sm"
                onClick={selectTableMode}
              >
                {tableOpen ? "Close" : "View"}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}
