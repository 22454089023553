import { Stream } from "@cloudflare/stream-react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Header from "../../components/Headers/Header";

interface PlayVideoPageProps {
  match: any;
}

export default function PlayVideoPage({ match }: PlayVideoPageProps) {
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Stream controls autoplay={true} src={match.params.id} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
