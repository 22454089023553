import { Fragment, useMemo } from "react";
import {
  useLocation,
  useParams,
  useRouteMatch,
  Link,
  Switch,
  Route,
} from "react-router-dom";
import { Col, Row } from "reactstrap";
import { CategoryCard } from "../../components/Categories/CategoryCard";
import Category from "../../interfaces/models/Category";
import Video from "../../interfaces/models/Video";
import MovementVideoPage from "./MovementVideoPage";
const MOVEMENT_CATEGORY_ID = "62e74170b36d2d6e35a32c9c";
export interface MovementCategoryProps {
  movementVideos: Array<Video>;
  categories: Array<Category>;
  childrenForParentCategory: {
    [key: string]: Array<string>;
  };
}
//TODO: Show loading spinner while fetching images or prefetch..
export function MovementCategoryPage(props: MovementCategoryProps) {
  const match = useRouteMatch();
  const location = useLocation();
  const { categoryId } = useParams<{ categoryId: string }>();
  const childCategories = useMemo(() => {
    if (props.childrenForParentCategory !== undefined) {
      const children =
        props.childrenForParentCategory[categoryId ?? MOVEMENT_CATEGORY_ID];
      return props.categories.filter((category) => {
        return children?.includes(category._id);
      });
    }
  }, [categoryId, props.categories, props.childrenForParentCategory]);

  function RenderIfCurrent() {
    const pathSplit = location.pathname.split("/");
    const bottomPath = pathSplit[pathSplit.length - 1];

    //We only render if we are on the bottom page or the current category
    if (bottomPath !== "movement.coach" && bottomPath !== categoryId)
      return <Fragment></Fragment>;

    //If this category has children categories, show them.
    if (childCategories?.length !== 0) {
      return (
        <Row
          style={{ minHeight: "70vh", justifyContent: "center" }}
          className="animated-card"
        >
          {childCategories?.map((cat, i) => {
            return (
              <Col key={i} xs={12} lg={4} className="w-100 my-3">
                <Link to={`${match.url}/${cat?._id}`}>
                  <CategoryCard
                    image={cat?.image}
                    description={cat?.description}
                  />
                </Link>
              </Col>
            );
          })}
        </Row>
      );
    }
    //If this category has no children categories (is video page), show them.
    else {
      return (
        <Fragment>
          <MovementVideoPage
            videos={props.movementVideos.filter(
              (video) => video.category === categoryId
            )}
          />
        </Fragment>
      );
    }
  }

  return (
    <>
      <div>
        {RenderIfCurrent()}
        {/* Recursively set up the next route in the tree */}
        <Switch>
          <Route path={`${match.url}/:categoryId`} key={Math.random()}>
            <MovementCategoryPage
              key={Math.random()}
              childrenForParentCategory={props.childrenForParentCategory}
              movementVideos={props.movementVideos}
              categories={props.categories}
            />
          </Route>
        </Switch>
      </div>
    </>
  );
}
