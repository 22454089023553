import { useMemo } from "react";
import useCategories from "../../hooks/useCategories";
import { useVideos } from "../../hooks/useVideos";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { generateMovementCategoryMap } from "../../helper/categoryGraphHelpers";
import { MovementCategoryPage } from "./MovementCategoryPage";
import CategoryBreadcrumbs from "../../components/Breadcrumbs/CategoryBreadcrumbs";
import Category from "../../interfaces/models/Category";
import Video from "../../interfaces/models/Video";

export default function MovementCoachPage() {
  const { data: videoData } = useVideos();
  const { data: categoryData } = useCategories();
  const categories: Array<Category> = useMemo(() => {
    return categoryData?.categories;
  }, [categoryData]);
  const movementVideos: Array<Video> = useMemo(() => {
    return videoData?.videos.filter((video) => video.movement === true);
  }, [videoData]);
  const childrenForParentCategory: {} = useMemo(() => {
    if (categories && movementVideos) {
      return generateMovementCategoryMap(categories, movementVideos);
    }
  }, [categories, movementVideos]);
  const match = useRouteMatch();
  return (
    <Router>
      <Switch>
        <Route path={`${match.url}`} key={Math.random()}>
          <CategoryBreadcrumbs categories={categories} />
          <MovementCategoryPage
            key={Math.random()}
            childrenForParentCategory={childrenForParentCategory}
            movementVideos={movementVideos}
            categories={categories}
          />
        </Route>
      </Switch>
    </Router>
  );
}
