import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { OnboardChecklist } from "../components/OnboardChecklist";
import { dashboardRoutes } from "../components/Routes/routingConstants";
import { useGetUsersRoutes } from "../hooks/useGetUsersRoutes";

const Layout = (props) => {
  const [showChecklist, setShowChecklist] = useState(false);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const getBrandText = () => {
    for (let i = 0; i < dashboardRoutes.length; i++) {
      if (props.location.pathname.indexOf(dashboardRoutes[i].path) !== -1) {
        return dashboardRoutes[i].name;
      }
    }
    return "desk.coach";
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const sideBarRoutes = useGetUsersRoutes();

  return (
    <>
      <Sidebar
        {...props}
        routes={sideBarRoutes}
        openChecklist={() => setShowChecklist(true)}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/deskcoach-dark.svg").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText(props.path)} />
        <Container className="pb-8 pt-5 pt-md-6" fluid>
          <OnboardChecklist
            isOpen={showChecklist}
            onClose={() => setShowChecklist(false)}
          />
          {props.children}
        </Container>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Layout;
