import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardText,
} from "reactstrap";
import { Task } from "../../interfaces/models/Task";
import { TaskImages } from "./TaskImages";
import { TaskModalContent } from "./TaskModalContent";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

interface ModalUsage {
  taskId: string;
  isStream: boolean;
}
interface TaskModalProps {
  usageModal: ModalUsage;
  setUsage: (newUsage: ModalUsage) => void;
  task: Task;
  isVideo: boolean;
}

export default function TaskViewModal({
  usageModal,
  setUsage,
  task,
  isVideo,
}: TaskModalProps) {
  return (
    <Modal
      isOpen={usageModal.taskId != null}
      isStream={usageModal.isStream}
      toggle={() => {
        setUsage({
          taskId: null,
          isStream: false,
        });
      }}
      className="modal-lg"
    >
      {usageModal.taskId && (
        <>
          <ModalHeader
            toggle={() => {
              setUsage({
                taskId: null,
                isStream: false,
              });
            }}
          ></ModalHeader>
          <ModalBody>
            {task ? (
              // Conditional rendering depending on whether Task is from standard or workhealth.coach pool
              isVideo ? (
                <TaskModalContent isStream={usageModal.isStream} item={task} />
              ) : (
                <>
                  <TaskImages
                    imageUrls={task.images}
                    imageAlt={"workhealth-image-cover"}
                  />
                  <AudioPlayer src={task.action_url} />
                  {task?.instructions && (
                    <Card>
                      <CardBody>
                        <CardText>
                          {task.instructions.map((i) => i.text)}
                        </CardText>
                      </CardBody>
                    </Card>
                  )}
                </>
              )
            ) : (
              <>
                <h1 className="d-flex justify-content-center">Sorry!</h1>
                <p className="d-flex justify-content-center">
                  The task could not be retrieved at this time. Please try again
                  later or contact a System Administrator.
                </p>
              </>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
}
