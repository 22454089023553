import { gql } from "@apollo/client";

export const getImageUploadURL = gql`
  query getImageUploadURL {
    getImageUploadURL {
      id
      uploadURL
    }
  }
`;
