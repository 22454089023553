import { useEffect, useState } from "react";
import { Stream } from "@cloudflare/stream-react";
import "../../_css/components/modal-contents.css";
import { useTimer } from "react-timer-hook";
import { Carousel, CarouselItem } from "reactstrap";
import TaskModalCarouselItem from "./TaskModalCarouselItem";
import { Task } from "../../interfaces/models/Task";
import Loading from "../Loading";

const videoId = (id: string) => id.match(/video.\b\w([.^\S]*)\w*\b/gm)[0];

interface TaskModalProps {
  item: Task;
  isStream: boolean;
  className?: string;
}

export const TaskModalContent = ({
  item,
  isStream,
  className = "",
}: TaskModalProps) => {
  const [streamIsReady, setStreamIsReady] = useState<boolean>(false);

  function inTenSeconds() {
    const now = new Date();
    now.setSeconds(now.getSeconds() + 10);
    return now;
  }
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { seconds, isRunning, resume, restart } = useTimer({
    expiryTimestamp: inTenSeconds(),
    onExpire: () => {
      next();
    },
    autoStart: false,
  });
  useEffect(() => {
    resume();
  }, [resume]);

  const next = () => {
    if (animating) return;
    restart(inTenSeconds(), true);
    const nextIndex =
      activeIndex === item.images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? item.images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <div className={`${className}`}>
      {isStream && (
        <>
        {!streamIsReady &&
          <div style={{gap: '2rem'}} className="p-6 d-flex flex-column justify-content-between align-items-center">
            <h2>Retrieving video...</h2>
            <Loading />
            <p>If your video doesn't load after a few seconds, please try refreshing the page.</p>
          </div>         
        }
        <Stream className={streamIsReady ? "visible" : "invisible"} controls autoplay={true} src={videoId(item.action_url)} onCanPlay={() => setStreamIsReady(true)} />
        </>
      )}
      {!isStream && (
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={false}
          className="position-relative"
        >
          {item.images.map((image, idx) => {
            let instructions = new Array(0);
            item?.instructions?.forEach((x) => {
              instructions.push(x.text);
            });
            if (
              item.images.length === 2 &&
              item?.instructions?.length === 1
            ) {
              instructions.push(item?.instructions[0]?.text);
              instructions[0] = null;
            }
            return (
              <CarouselItem
                tag="div"
                key={image}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
              >
                <TaskModalCarouselItem
                  key={image}
                  seconds={seconds}
                  isRunning={isRunning}
                  start={resume}
                  previous={previous}
                  next={next}
                  setAnimating={setAnimating}
                  image={image}
                  text={instructions[idx]}
                />
              </CarouselItem>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
