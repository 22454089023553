import { useMsal } from "@azure/msal-react";
import { JwtToken } from "../interfaces/models/JwtToken";
import { useEffect, useState } from "react";

export const useIsUserSuperAdmin = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const { instance } = useMsal();

  useEffect(() => {
    const checkUserRole = () => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const userAccount = accounts[0];
        let userJwtToken = (userAccount?.idTokenClaims as JwtToken) ?? null;
        if (userJwtToken?.extension_Role === "SuperAdministrator") {
          setIsSuperAdmin(true);
        }
      }
    };
    checkUserRole();
  }, [instance]);

  return isSuperAdmin;
};
