/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


export default function useRoutes({ data, error }) {
    const routes = []

    if (error) { throw new Error(error.message) }

    if (data?.categories) {
        const categories = [...data?.categories]?.sort((a, b) => b.level - a.level)

        const transformPath = (path) => path.replace(/[^+\w]/gm, '.')

        categories.forEach(c => {
            // max level
            if (c.level === 5) {
                routes.push({
                    id: c._id,
                    path: `/dash/${c.description.toLowerCase()}`,
                    name: c.description,
                    categories: categories.filter(ca => ca.parent_id === c._id)
                })
            }

            const parent = routes.find(r => r.id === c.parent_id)
            if (parent) {
                const cats = categories.filter(ca => ca.parent_id === c._id)
    
                routes.push({
                    id: c._id,
                    path: `${parent.path}/${transformPath(c.description.toLowerCase())}`,
                    name: c.description,
                    categories: cats.length > 0 ? cats : [c]
                })
            }
        })
    }

    return routes
}
