import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import TaskSequenceCard from "../../components/Tasks/TaskSequence";
import Loading from "../../components/Loading";
import { Alert } from "reactstrap";
import { useWorkHealthTaskSequence } from "../../hooks/useWorkHealthSequence";
import { Sequence } from "../../interfaces/models/TaskSequence";

export function ViewWorkHealthTaskSequencePage() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { workhealthTaskSequenceId } = useParams<{
    workhealthTaskSequenceId: string;
  }>();

  const { data, loading, error } = useWorkHealthTaskSequence(
    workhealthTaskSequenceId
  );
  const sequence = data?.workhealth_sequence;

  const workHealthSequences: Sequence[] = data?.workhealth_sequence.sequence
    .filter((seq: any) => seq?.workhealth_task !== null)
    .map((seq: any) => {
      return { ...seq, task: { ...seq.workhealth_task } };
    });

  const workhealth_seq = {
    ...data?.workhealth_sequence,
    sequence: workHealthSequences,
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Alert color="danger">{error.message}</Alert>}
      {sequence && sequence.length === 0 && (
        <Alert color="warning">
          No category found using the ID: {workhealthTaskSequenceId}
        </Alert>
      )}
      {sequence && (
        <TaskSequenceCard
          sequence={workhealth_seq}
          initialTaskId={searchParams.get("taskId")}
          isWorkHealth={true}
        />
      )}
    </>
  );
}
