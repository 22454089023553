import { Frequency, RRule, rrulestr } from "rrule";
import {
  RRuleEndOnDateFieldName,
  RRuleEndAfterCountFieldName,
  RRuleEndNever,
} from "../pages/EmpowerMe/Scheduling/EmpowerMeFormFieldConstants";

export function parseRRule(rRule: string) {
  try {
    const rruleObject: RRule = rrulestr(rRule);
    let humanReadable = rruleObject.toText();
    return humanReadable[0].toUpperCase() + humanReadable.slice(1);
  } catch (ex) {
    console.error("Error occurred while trying to parse rRule.");
  }
  return "No repeat";
}

export function getLocalDate(dateVal: string) {
  let date = new Date(dateVal);
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}

export function getRRuleFrequency(rrule: string) {
  const taskRRule = rrulestr(rrule);
  const freq = taskRRule.options.freq;
  return freq as Frequency;
}

export function getRRuleEndCondition(rrule: string) {
  const taskRRule = rrulestr(rrule);
  if (taskRRule.options.until) {
    return RRuleEndOnDateFieldName;
  } else if (taskRRule.options.count) {
    return RRuleEndAfterCountFieldName;
  }
  return RRuleEndNever;
}
