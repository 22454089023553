import moment from "moment";
import { HeatmapPeriod } from "../../interfaces/HeatmapPeriod";

export const heatmapPeriods: HeatmapPeriod[] = [
  {
    title: "Last Month",
    startDate: moment().subtract(1, "months").toDate(),
  },
  {
    title: "Last 6 Months",
    startDate: moment().subtract(6, "months").toDate(),
  },
];
