import { gql, useQuery } from '@apollo/client';

export default function useCategories() {
    return useQuery(gql`
    query getCategories {
        categories {
            _id
            description
            image
            level
            parent_id
        }
    }
  `);
}