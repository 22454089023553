import moment from "moment";
import { EmpowerScheduledTask } from "../interfaces/models/EmpowerTask";
import { RRule } from "rrule";

export const IsEmpowerNudgeActive = (scheduledNudge: EmpowerScheduledTask) => {
    const utcNow = moment.utc();
    // Is still active if start date has yet to occur
    const startDate = moment(scheduledNudge.start_date);
    if (startDate.isAfter(utcNow) ) {
      return true;
    }
    // Is stil active if it has a recurrence rule that hasn't ended
    const rrule = scheduledNudge.recurrence_rule ? RRule.fromString(scheduledNudge.recurrence_rule) : null;
    if (rrule && rrule.after(utcNow.toDate())) {
        return true;
    }
    // Is not active if the nudge doesn't satisfy the above checks
    return false;
}
