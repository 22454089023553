import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            <a
              className="font-weight-bold ml-1"
              href="https://www.deskcoach.com.au/"
            >
              © {new Date().getFullYear()}{" "}
              desk.coach
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
          <NavItem>
              <NavLink
                href="https://www.deskcoach.com.au/privacy-policy"
              >
                Privacy Policy
              </NavLink>
          </NavItem>
          <NavItem>
              <NavLink
                href="https://www.deskcoach.com.au/terms-and-conditions"
              >
                Terms and Conditions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://www.deskcoach.com.au/team"
              >
                About Us
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
