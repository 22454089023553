import { gql } from "@apollo/client";

export const CreateEmpowerMeTask = gql`
  mutation CreateEmpowerMeTask(
    $task_id: String!
    $start_date: String!
    $recurrence_rule: String
  ) {
    me_CreateEmpowerMeTask(
      task_id: $task_id
      start_date: $start_date
      recurrence_rule: $recurrence_rule
    ) {
      _id
      scheduled_tasks {
        _id
        task_id
        start_date
        recurrence_rule
      }
    }
  }
`;

