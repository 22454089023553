import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTaskSequence } from "../../hooks/useTaskSequence";
import Loading from "../../components/Loading";
import { Alert } from "reactstrap";
import TaskSequenceCard from "../../components/Tasks/TaskSequence";

export function ViewTaskSequencePage() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { taskSequenceId } = useParams<{ taskSequenceId: string }>();

  const { data, loading, error } = useTaskSequence(taskSequenceId);
  const sequence = data?.task_sequence;

  return (
    <>
      {loading && <Loading />}
      {error && <Alert color="danger">{error.message}</Alert>}
      {sequence && sequence.length === 0 && (
        <Alert color="warning">
          No category found using the ID: {taskSequenceId}
        </Alert>
      )}
      {sequence && (
        <TaskSequenceCard
          sequence={sequence}
          initialTaskId={searchParams.get("taskId")}
        />
      )}
    </>
  );
}
