import { Task } from "../../interfaces/models/Task";
import { TimelineTitle } from "./TimelineTitle";

export enum TimelineItemStatus {
  Completed = "Completed",
  Upcoming = "Upcoming",
  Scheduled = "Scheduled",
  WorkLifeBalance = "Work Life Balance",
  WorkLifeBalanceEnd = "Work Life Balance End",
}

type TimelineItemProps = {
  task: Task;
  status: TimelineItemStatus;
  assignedAt?: Date;
};

export default function TimelineItem({
  task,
  assignedAt,
  status,
}: TimelineItemProps) {
  return (
    <div
      className="timeline timeline-one-side"
      data-timeline-axis-style="dashed"
      data-timeline-content="axis"
    >
      <div className="timeline-block pb-3">
        {/* Icon */}
        {getMetaForTimelineStatus(status).icon}

        {/* Description */}
        <div className="timeline-content">
          <TimelineTitle
            date={assignedAt}
            path={task.path}
            description={task.name}
          />
          <p className="text-sm mb-0">{task.description}</p>

          {/* Badge */}
          {getMetaForTimelineStatus(status).badge}
        </div>
      </div>
    </div>
  );
}

const getMetaForTimelineStatus = (
  status: TimelineItemStatus
): { icon: JSX.Element; badge?: JSX.Element } => {
  switch (status) {
    case TimelineItemStatus.Scheduled:
      return {
        icon: (
          <span className="timeline-step badge-info">
            <i className="ni ni-time-alarm"></i>
          </span>
        ),
        badge: (
          <div className="mt-1">
            <span className="badge badge-info badge-pill">
              {TimelineItemStatus.Scheduled}
            </span>
          </div>
        ),
      };
    case TimelineItemStatus.Upcoming:
      return {
        icon: (
          <span className="timeline-step badge-info">
            <i className="ni ni-time-alarm"></i>
          </span>
        ),
        badge: (
          <div className="mt-1">
            <span className="badge badge-info badge-pill">
              {TimelineItemStatus.Upcoming}
            </span>
          </div>
        ),
      };
    case TimelineItemStatus.Completed:
      return {
        icon: (
          <span className="timeline-step badge-success">
            <i className="ni ni-check-bold"></i>
          </span>
        ),
      };
    case TimelineItemStatus.WorkLifeBalance:
      return {
        badge: (
          <div className="mt-1">
            <span className="badge badge-warning badge-pill">
              {TimelineItemStatus.WorkLifeBalance}
            </span>
          </div>
        ),
        icon: (
          <span className="timeline-step badge-warning">
            <i className="ni ni-watch-time"></i>
          </span>
        ),
      };
    case TimelineItemStatus.WorkLifeBalanceEnd:
      return {
        badge: (
          <div className="mt-1">
            <span className="badge badge-danger badge-pill">
              {/* WorkLifeBalanceEnd should display the same label as WorkLifeBalance */}
              {TimelineItemStatus.WorkLifeBalance}
            </span>
          </div>
        ),
        icon: (
          <span className="timeline-step badge-danger">
            <i className="ni ni-notification-70"></i>
          </span>
        ),
      };
  }
};
