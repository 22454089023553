import React, { useCallback } from "react";
import { bisector } from "d3-array";
import {
  TooltipWithBounds,
  useTooltip,
  useTooltipInPortal,
  defaultStyles,
} from "@visx/tooltip";
import { performance_colours } from "./utils";
import Loading from "../../components/Loading";
import { AreaChart } from "./AreaChart";
import { curveNatural } from "@visx/curve";
import AreasSeparation from "./AreasSeparation";
import "./_css/style.css";

const AreaSeparationChart = ({
  data,
  displayMode,
  getY,
  getX,
  height,
  innerHeight,
  innerWidth,
  inspectMode,
  margin,
  timeFormatter,
  tick_value,
  topChartBottomMargin,
  width,
  xAxisProperties,
  yAxisProperties,
  xScale,
  yScale,
}) => {
  const bisectDate = bisector((d) => getX(d)).left;

  const { containerRef, containerBounds } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const tooltipStyles = {
    ...defaultStyles,
    background: "rgba(0,0,0, .7)",
    color: "var(--white)",
    padding: ".2rem .75rem",
  };

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip({
    tooltipOpen: false,
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: null,
  });

  const handleTooltip = useCallback(
    (e) => {
      const containerX =
        ("clientX" in e ? e.clientX : 0) - containerBounds.left;
      const containerY = ("clientY" in e ? e.clientY : 0) - containerBounds.top;

      const coordinateX = e.target.getAttributeNS(null, "cx");

      const x0 = xScale.invert(coordinateX);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];

      let d = d0;
      if (d1 && getX(d1)) {
        d =
          x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }

      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: d,
      });
    },
    [bisectDate, containerBounds, data, getX, showTooltip, xScale]
  );

  const threshold = {
    low_count: 4,
    optimal: 8,
    overwork: 11,
    burnout: 0,
  };

  if (!data || !data.length) return <Loading />;

  return (
    <>
      <svg width={width} height={height} ref={containerRef}>
        {displayMode === "Monthly" && (
          <AreaChart
            {...{
              data,
              getX,
              getY,
              handleTooltip,
              hideTooltip,
              innerHeight,
              innerWidth,
              tick_value,
              width,
              xScale,
              yScale,
            }}
            axesProps={{
              xAxisProperties,
              yAxisProperties,
            }}
            margin={{ ...margin, bottom: topChartBottomMargin }}
            inspectMode={inspectMode}
          />
        )}

        {displayMode === "Weekly" && (
          <AreaChart
            {...{
              data,
              getX,
              getY,
              handleTooltip,
              hideTooltip,
              innerHeight,
              innerWidth,
              margin,
              tick_value,
              width,
              xScale,
              yScale,
            }}
            axesProps={{
              xAxisProperties,
              yAxisProperties,
            }}
            areaSeparationsComponent={
              <>
                <AreasSeparation
                  id={`optimal__low-count`}
                  data={data}
                  x={(d) => xScale(getX(d)) ?? 0}
                  y0={(d) => yScale(getY(d)) ?? 0}
                  y1={yScale(threshold.low_count)}
                  separation={yScale(threshold.low_count)}
                  clipAboveTo={yScale(threshold.optimal)}
                  clipBelowTo={innerHeight}
                  curve={curveNatural}
                  belowAreaProps={{
                    fill: performance_colours["Low Count"],
                    fillOpacity: 1,
                  }}
                  aboveAreaProps={{
                    fill: performance_colours["Optimal"],
                    fillOpacity: 1,
                  }}
                />
                <AreasSeparation
                  id={`overwork__burnout`}
                  data={data}
                  x={(d) => xScale(getX(d)) ?? 0}
                  y0={(d) => yScale(getY(d)) ?? 0}
                  y1={yScale(threshold.optimal)}
                  separation={yScale(threshold.overwork)}
                  clipAboveTo={threshold.burnout}
                  clipBelowTo={yScale(threshold.optimal)}
                  curve={curveNatural}
                  belowAreaProps={{
                    fill: performance_colours["Overwork"],
                    fillOpacity: 0.7,
                  }}
                  aboveAreaProps={{
                    fill: performance_colours["Burnout"],
                    fillOpacity: 1,
                  }}
                />
              </>
            }
            inspectMode={inspectMode}
          ></AreaChart>
        )}
      </svg>

      {tooltipData && (
        <div>
          <TooltipWithBounds
            key={`tooltip_${getX(tooltipData)}`}
            top={tooltipTop - 125}
            left={tooltipLeft + 30}
            style={tooltipStyles}
            className="card rounded"
          >
            <h5 className="text-white mb-1">Performance :</h5>
            <span className="mb-2">
              Taken on: {`${timeFormatter(getX(tooltipData))}`}
            </span>
            <span className="h3 text-white text-uppercase">
              <strong>{`Average Count: ${
                Math.round(getY(tooltipData) * 100) / 100
              }`}</strong>
            </span>
          </TooltipWithBounds>
        </div>
      )}
    </>
  );
};

export { AreaSeparationChart };
