import { useState } from "react";

type ToggleButton = {
  label: string;
  onSelected: () => void;
};

interface CustomToggleButtonGroupProps {
  buttons: ToggleButton[];
  initiallySelectedIndex?: number;
  disabled?: boolean;
}

export default function CustomToggleButtonGroup(
  props: CustomToggleButtonGroupProps
) {
  const [selectedButton, setSelectedButton] = useState<number>(
    props.initiallySelectedIndex ?? null
  );

  return (
    <div className="btn-group btn-group-toggle mb-3 w-50" data-toggle="buttons">
      {props.buttons.map((button, i) => {
        return (
          <button
            type="button"
            disabled={props.disabled}
            key={button.label}
            className={`btn ${
              i === selectedButton ? "active btn-primary" : "btn-secondary"
            }`}
            onClick={() => {
              if (selectedButton !== i) {
                button.onSelected();
              }
              setSelectedButton(i);
            }}
          >
            {button.label}
          </button>
        );
      })}
    </div>
  );
}
