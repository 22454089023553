import { useQuery, gql } from "@apollo/client";
import {
  DASHBOARD_ROOT,
  dashboardRoutes,
  DESK_COACH,
} from "../components/Routes/routingConstants";
import { useIsUserSuperAdmin } from "./useIsUserSuperAdmin";

export const useGetUsersRoutes = () => {
  const { data, loading, error, called } = useQuery(
    gql`
      query getUsersLicensedProducts {
        me {
          user_license {
            products
          }
        }
      }
    `
  );

  // Return all routes if User is a 'SuperAdministrator'
  if (useIsUserSuperAdmin()) {
    // Show DC library in sidebar for super admins
    const dcLibraryIndex = dashboardRoutes.findIndex(
      (route) => route.path === `${DASHBOARD_ROOT}/${DESK_COACH}`
    );
    if (dcLibraryIndex >= 0) {
      dashboardRoutes[dcLibraryIndex].showInSidebar = true;
    }
    return dashboardRoutes;
  }

  // Filter out any routes that the the user is not licensed to use
  if (called && !loading) {
    if (data) {
      const products: string[] = data.me.user_license?.products?.split(",");

      // Exclude any routes that the user is not licensed to use
      const filteredRoutes = dashboardRoutes.filter((route) => {
        if (
          route.adminRequired ||
          (route.licenseRequired && !products.includes(route.licenseRequired))
        ) {
          return null;
        }
        return route;
      });
      return filteredRoutes;
    } else if (error) {
      throw new Error("Could not retrieve user's licenses.");
    }
  }
  return [];
};
