import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  Carousel,
  CarouselItem,
  CardTitle,
} from "reactstrap";
import {
  Section,
  healthHubInformation as carouselItems,
} from "../../assets/data/HealthHubInfo";
import { useWindowResize } from "../../hooks/useWindowResize";

export default function HealthHubCarousel() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);
  const [portionedSlides, setPortionedSlides] = useState<Section[][]>([]);
  const { screenSizeCategory } = useWindowResize();

  // Set number if slides to show depending on size of screen
  useEffect(() => {
    if (screenSizeCategory === "mobile") {
      setPortionedSlides(SeparatePortions(carouselItems, 1));
    } else if (screenSizeCategory === "medium") {
      setPortionedSlides(SeparatePortions(carouselItems, 2));
    } else {
      setPortionedSlides(SeparatePortions(carouselItems, 3));
    }
    setActiveIndex(0);
  }, [screenSizeCategory]);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === portionedSlides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? portionedSlides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = portionedSlides.map((items, index) => (
    <CarouselItem 
      key={index}
      className="h-100" 
      active={index === activeIndex}
      onExited={() => setAnimating(false)}
      onExiting={() => setAnimating(true)}
    >
      <div className="d-flex flex-row justify-content-evenly">
        {items.map((item, index) => (
          <Card
            style={{ flex: 1 }}
            className="ml-1 mr-1"
            key={index}
          >
            <a href={item.link.url} target="_blank" rel="noopener noreferrer">
              <CardBody className="d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <img
                    src={item.icon}
                    alt={item.link.text}
                    style={{ width: "6rem" }}
                  />
                </div>
                <CardText className="text-center text-dark">
                  {item.title}
                </CardText>
              </CardBody>
            </a>
          </Card>
        ))}
      </div>
    </CarouselItem>
  ));

  return (
    <Card>
      <CardTitle>
        <h2 className="mt-2 mb-0 ml-2">Health Hub</h2>
      </CardTitle>
      <CardBody>
        <Carousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={null}
        >
          {slides}
          <div className="carousel-control-prev" onClick={previous}>
            <i
              className="fas fa-chevron-circle-left"
              style={{ fontSize: "2rem", color: "black" }}
            ></i>
          </div>
          <div className="carousel-control-next" onClick={next}>
            <i
              className="fas fa-chevron-circle-right"
              style={{ fontSize: "2rem", color: "black" }}
            ></i>
          </div>
        </Carousel>
      </CardBody>
    </Card>
  );
}

function SeparatePortions(items: Section[], portionsPerSlide: number) {
  const oldItems = [...items];
  var portions: Section[][] = [];

  // Loop until slides are empty
  while (oldItems.length > 0) {
    if (oldItems.length >= portionsPerSlide) {
      portions.push(oldItems.splice(0, portionsPerSlide));
    } else {
      portions.push(oldItems);
      break;
    }
  }

  return portions;
}
