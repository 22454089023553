import { Fragment } from "react";
import { getImageUrl } from "../../helper/getImageUrl";
import ReactMarkdown from "react-markdown";
import {
  Card,
  CardBody,
  CarouselControl,
  CardText,
  Button,
  Row,
} from "reactstrap";

export default function TaskModalCarouselItem(props) {
  // Replace new line characters with double space then newline - this is useful for Markdown formatting
  let text = props?.text?.replace(/\n/g, "  \n");
  // Apply header/bold of the first line
  text = "## " + text;
  
  // Additonally bold this text
  text = text?.replace(
    "Click this notification for a video",
    "**Click this notification for a video**"
  );

  return (
    <Fragment>
      <div className="position-relative">
        <img
          src={getImageUrl(props.image)}
          alt={`modal-card-${props.image}`}
          className="modal-image w-100"
        />
        <CarouselControl
          className="carousel-control"
          direction="prev"
          directionText="Previous"
          onClickHandler={props.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={props.next}
        />
      </div>
      <div>
        <Card>
          <CardBody>
            <CardText>
              <ReactMarkdown>{text}</ReactMarkdown>
            </CardText>
            <Timer
              start={props.start}
              isRunning={props.isRunning}
              seconds={props.seconds}
            />
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
}

function Timer({ isRunning, seconds, start }) {
  return (
    <Row className="justify-content-center">
      <Button onClick={start}>
        <span className="btn-inner--icon">
          <i
            className={`fa fa-${isRunning ? "pause" : "play"}`}
            aria-hidden="true"
          />
        </span>
        <span className="btn-inner--text pl-3">
          0 : {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      </Button>
    </Row>
  );
}
