import { gql, useQuery } from "@apollo/client";

export default function useWorkHealthSequences() {
  return useQuery(gql`
    query getWorkHealthSequences {
      workhealth_sequences {
        _id
        name
        include_in_rotation
        image
        sequence {
          order
          workhealth_task {
            _id
            name
            action_url
            category
            description
            images
          }
        }
      }
    }
  `);
}
