import axios from "axios";
import { useState } from "react";


export default function UseVideoUpload() {
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const uploadImage = async (
    signedUrl: string,
    imageFile: File
  ): Promise<boolean> => {
    setUploadingImage(true);

    const form: FormData = new FormData();
    // Attach image to form to send with upload request
    form.append("file", imageFile);

    var options = {
      method: "POST",
      url: signedUrl,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: form,
    };

    // Make request to upload image
    try {
      await axios.request(options);
      return true;
    } 
    catch (error) {
      return false;
    }
    finally {
      setUploadingImage(false);
    }
  };

  return { uploadImage, uploadingImage };
}
