import { gql } from "@apollo/client";

export const getVideoUploadURLQuery = gql`
  query getVideoUploadURL($fileName: String!, $fileSize: Int!) {
    getVideoUploadURL(fileName: $fileName fileSize: $fileSize) {
      uploadURL
      cloudflareId
    }
  }
`;
