import {
  Card,
  CardBody,
  CardText,
  Col,
  Row,
  Button,
  UncontrolledTooltip as Tooltip,
} from "reactstrap";
import classnames from "classnames";
import "../../_css/components/navigations-card.css";
import { TaskImages } from "./TaskImages";
import { getImageUrl, isUrlValidImage } from "../../helper/getImageUrl";
import {
  NudgeClickUrlType,
  getNudgeClickUrlType,
} from "../../interfaces/models/NudgeClickUrlType";

interface TaskCardProps {
  className?: string;
  id: string;
  title: string;
  action_url: string;
  body: string;
  images?: string[];
  openModal: () => void;
  openStream: () => void;
  openEditModal?: () => void;
  index: number;
  isWorkHealth: boolean;
  canEdit: boolean;
}

const TITLE_TRUNCATE_LIMIT: number = 30;
const DESCRIPTION_TRUNCATE_LIMIT: number = 200;

export const TaskCard = ({
  className = "",
  id,
  title,
  action_url,
  body,
  images = null,
  openModal,
  openStream,
  openEditModal,
  index,
  isWorkHealth,
  canEdit,
}: TaskCardProps) => {
  const imageUrls = images.map((url) =>
    isUrlValidImage(url) ? url : getImageUrl(url)
  );

  return (
    <Card
      className={`task-card h-100 shadow ${className}`}
      id="nav-card"
      onClick={(e) => {
        e.stopPropagation();
        openModal();
      }}
    >
      <div className={classnames("card-img-top", "image-card")}>
        <Row>
          <Col className="column-no-pad">
            {images && <TaskImages imageUrls={imageUrls} imageAlt={title} />}
          </Col>
          {canEdit && !isWorkHealth && (
            <Button
              variant="primary"
              className={"task-card-edit-button"}
              style={{
                position: "absolute",
                top: "25%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                openEditModal();
              }}
            >
              Edit
            </Button>
          )}
        </Row>
      </div>
      <CardBody>
        <h5
          id={`title_${id}_${index}`}
          className="text-uppercase  mb-0 card-title"
        >
          {title.length > TITLE_TRUNCATE_LIMIT
            ? title.slice(0, TITLE_TRUNCATE_LIMIT - 5) + "..."
            : title}
        </h5>
        <CardText className="my-4">
          {body && body.length > 0
            ? body.length > DESCRIPTION_TRUNCATE_LIMIT
              ? body.slice(0, DESCRIPTION_TRUNCATE_LIMIT - 10) + "..."
              : body
            : "No descriptions for this notification."}
        </CardText>
        <Row className="justify-content-center">
          {getNudgeClickUrlType(action_url) === NudgeClickUrlType.Video && (
            <Button
              className="btn"
              onClick={(e) => {
                e.stopPropagation();
                openStream();
              }}
            >
              <span className="btn-inner--icon">
                <i className="fa fa-play" aria-hidden="true" />
              </span>
              <span className="btn-inner--text">
                Play {isWorkHealth ? "Audio" : "Video"}
              </span>
            </Button>
          )}
        </Row>
      </CardBody>
      {title.length > TITLE_TRUNCATE_LIMIT && (
        <TitleTooltip target={`title_${id}_${index}`} value={title} />
      )}
    </Card>
  );
};

const TitleTooltip = ({ target, value }) => {
  return (
    <Tooltip {...{ target }} hideArrow>
      {value}
    </Tooltip>
  );
};
