import { DirectedGraph } from "graphology";
import { bfsFromNode } from "graphology-traversal";
import Category from "../interfaces/models/Category";
import Video from "../interfaces/models/Video";
export const ROOT_NODE_NAME = "root";
export const generateMovementCategoryMap = (
  allCategories: Array<Category>,
  allVideos: Array<Video>
) => {
  const result = {};
  //Build a directed graph from the category map, linking all nodes
  const allCategoryGraph = buildCategoryGraph(allCategories, allVideos);
  //Remove all nodes which don't contain movement videos
  removeNonMovementNodes(allCategoryGraph);
  //Convert into a hashmap for return
  allCategoryGraph.nodes().forEach((node) => {
    result[node] = allCategoryGraph.outNeighbors(node);
  });
  return result;
};

const buildCategoryGraph = (
  allCategories: Array<Category>,
  allVideos: Array<Video>
) => {
  const graph = new DirectedGraph();
  graph.addNode(ROOT_NODE_NAME, { containsMovement: false });
  //Add all nodes
  allCategories.forEach((category) => {
    graph.addNode(category._id, {
      containsMovement: allVideos.some(
        (video) => video.category === category._id
      ),
    });
  });
  //Add directed edges
  allCategories.forEach((category) => {
    //Draw edge from parent to current node
    graph.addDirectedEdge(category.parent_id ?? ROOT_NODE_NAME, category._id);
  });

  return graph;
};

//Remove any node that doesn't have a child with a movement video !
const removeNonMovementNodes = (categoryGraph: DirectedGraph): void => {
  const deadNodes = [];
  categoryGraph.nodes().forEach((node) => {
    let result = false;
    bfsFromNode(categoryGraph, node, function (node, attr, depth) {
      result = result || attr.containsMovement;
      return attr.containsMovement;
    });
    if (result === false) {
      deadNodes.push(node);
    }
  });
  deadNodes.forEach((node) => {
    categoryGraph.dropNode(node);
  });
};
