import React, { Suspense } from "react";
import { useNotificationRoutes, useCategories } from "../../hooks";
import { Route, Switch, Redirect } from "react-router-dom";
import Loading from "../../components/Loading";
import "../../_css/components/notification-categories-cards.css";
import Category from "../../interfaces/models/Category";
import { MY_INTENTION_COACH } from "../../components/Routes/routingConstants";

const IntentionCategory = React.lazy(
  () => import("../../components/CategoryTiles/CategoryTiles")
);

interface RouteInfo {
  id: string;
  path: string;
  name: string;
  categories: Category[];
}

function IntentionCategoriesPage() {
  const { data, loading, error } = useCategories();
  const routes: RouteInfo[] = useNotificationRoutes({ data, error });

  const intentionCategory = data?.categories.filter((category: Category) => {
    return category.description === MY_INTENTION_COACH.toLowerCase();
  })[0];
  const intentionCategories =
    data?.categories.filter(
      (category: Category) => category?.parent_id === intentionCategory?._id
    ) ?? [];

  const getRoutes = (routes: RouteInfo[]) => {
    return (
      routes &&
      routes.length > 0 &&
      routes.map((route, key) => {
        return (
          <Route
            key={key}
            path={route.path}
            exact
            render={(routerProps) => {
              return (
                <IntentionCategory
                  routerProps={routerProps}
                  categories={intentionCategories}
                  videoOnly={true}
                />
              );
            }}
          />
        );
      })
    );
  };

  if (loading) return <Loading />;

  return (
    <>
      <Suspense fallback={<>Loading</>}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/dash/intention" to="/dash/myintention.coach" />
        </Switch>
      </Suspense>
    </>
  );
}

export { IntentionCategoriesPage as Intention };
