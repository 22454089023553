import { Field, useFormikContext } from "formik";
import { Frequency } from "rrule";
import {
  RRuleFrequencyFieldName,
  RRuleIntervalFieldName,
} from "./EmpowerMeFormFieldConstants";
import styles from "./EmpowerMeScheduling.module.scss";

interface RecurrenceSelectionProps {
  recurrenceEnabled: boolean;
  endConditionFieldName: string;
  endNever: string;
  endOnDateFieldName: string;
  endAfterCountFieldName: string;
}

export function RecurrenceSelection({
  recurrenceEnabled,
  endConditionFieldName,
  endNever,
  endOnDateFieldName,
  endAfterCountFieldName,
}: RecurrenceSelectionProps) {
  const formik = useFormikContext();

  async function validateRepeatInterval(repeatInterval: any) {
    if (!recurrenceEnabled) return;
    if (repeatInterval === null || typeof repeatInterval === "string") {
      return "Please provide a valid number";
    }
    const num = Number(repeatInterval);
    if (!Number.isInteger(num) || num <= 0) {
      return "Please provide a valid number";
    }
    // TODO: maybe disallow exceedingly large numbers?
  }

  async function handleFrequencyChange(e: any) {
    await formik.setFieldValue(RRuleFrequencyFieldName, e.target.value);
  }

  function validateEndsOnField(endDate: string) {
    if (
      !recurrenceEnabled ||
      formik.getFieldMeta(endConditionFieldName).value !== endOnDateFieldName
    )
      return;

    if (!(endDate !== "" && endDate !== undefined)) {
      return "Please provide a valid date";
    }

    // Verify end date is ahead of start date
    const startDate = formik.getFieldMeta("date").value;

    if (startDate) {
      const sDate = new Date(Date.parse(startDate as string));
      const eDate = new Date(Date.parse(endDate));
      if (eDate < sDate) {
        return "End date must be ahead of selected start date";
      }
    }
  }
  function validateEndsAfterField(occurrences: string) {
    if (
      !recurrenceEnabled ||
      formik.getFieldMeta(endConditionFieldName).value !==
        endAfterCountFieldName
    )
      return;

    const parsedToNumber = Number(occurrences);
    if (!Number.isInteger(parsedToNumber) || parsedToNumber <= 0) {
      return "Must be a positive whole number";
    }
  }

  async function handleEndsRadioChange(e: any) {
    await formik.setFieldValue(endConditionFieldName, e.target.value);

    switch (e.target.value) {
      case endNever:
        formik.setFieldError(endOnDateFieldName, undefined);
        formik.setFieldError(endAfterCountFieldName, undefined);
        break;
      case endOnDateFieldName:
        formik.setFieldError(endAfterCountFieldName, undefined);
        break;
      case endAfterCountFieldName:
        formik.setFieldError(endOnDateFieldName, undefined);
        break;
      default:
        break;
    }
  }

  return (
    <div
      className={`${styles.recurrenceFields} ${!recurrenceEnabled && "d-none"}`}
    >
      {/* Recurence rule fields */}
      <div className={styles.recurrencePrompt}>
        <div className={`${styles.recurrenceRepeatRule} mb-3`}>
          <label>Repeat every</label>
          <Field
            name={RRuleIntervalFieldName}
            type="number"
            min="1"
            className="form-control"
            disabled={!recurrenceEnabled}
            validate={validateRepeatInterval}
          />
          <select
            className="form-control"
            name={RRuleFrequencyFieldName}
            onChange={(e) => handleFrequencyChange(e)}
            disabled={!recurrenceEnabled}
            defaultValue={
              (formik.getFieldMeta(RRuleFrequencyFieldName)
                .value as Frequency) ?? Frequency.DAILY
            }
          >
            <option value={Frequency.DAILY}>days</option>
            <option value={Frequency.WEEKLY}>weeks</option>
            <option value={Frequency.MONTHLY}>months</option>
          </select>
        </div>
        <p className={styles.fieldError}>
          {formik.getFieldMeta(RRuleIntervalFieldName).touched &&
            formik.getFieldMeta(RRuleIntervalFieldName).error}
        </p>
      </div>

      {/* End selector */}
      <label>Ends</label>
      <div className={styles.recurrenceFieldRadio}>
        <input
          type="radio"
          name={endConditionFieldName}
          onChange={(e) => handleEndsRadioChange(e)}
          value={endNever}
          disabled={!recurrenceEnabled}
          defaultChecked={
            formik.getFieldMeta(endConditionFieldName).value === endNever
          } // TODO: potentially clean this
        />
        <p className={styles.recurrenceFieldLabel}>Never</p>
      </div>

      <div className={`${styles.recurrenceFieldRadio}`}>
        <div className="d-flex align-items-center justify-space-between">
          <input
            type="radio"
            name={endConditionFieldName}
            onChange={(e) => handleEndsRadioChange(e)}
            value={endOnDateFieldName}
            disabled={!recurrenceEnabled}
            defaultChecked={
              formik.getFieldMeta(endConditionFieldName).value ===
              endOnDateFieldName
            }
          />
          <p className={styles.recurrenceFieldLabel}>On</p>
        </div>
        <Field
          className="form-control"
          name={endOnDateFieldName}
          type="date"
          disabled={
            !recurrenceEnabled ||
            formik.getFieldMeta(endConditionFieldName).value !==
              endOnDateFieldName
          }
          validate={validateEndsOnField}
        />
      </div>
      <p className={styles.fieldError}>
        {formik.getFieldMeta(endConditionFieldName).value ===
          endOnDateFieldName && formik.getFieldMeta(endOnDateFieldName).error}
      </p>

      <div className={`${styles.recurrenceFieldRadio}`}>
        <div className="d-flex align-items-center justify-space-between">
          <input
            type="radio"
            name={endConditionFieldName}
            onChange={(e) => handleEndsRadioChange(e)}
            value={endAfterCountFieldName}
            disabled={!recurrenceEnabled}
            defaultChecked={
              formik.getFieldMeta(endConditionFieldName).value ===
              endAfterCountFieldName
            }
          />
          <p className={styles.recurrenceFieldLabel}>After</p>
        </div>
        <div className="d-flex align-items-center justify-space-between">
          <Field
            className="form-control"
            name={endAfterCountFieldName}
            type="number"
            disabled={
              !recurrenceEnabled ||
              formik.getFieldMeta(endConditionFieldName).value !==
                endAfterCountFieldName
            }
            validate={validateEndsAfterField}
          />
          <p className={styles.recurrenceFieldLabel}>occurrences</p>
        </div>
      </div>

      {/* Error Message */}
      <p className={styles.fieldError}>
        {formik.getFieldMeta(endConditionFieldName).value ===
          endAfterCountFieldName &&
          formik.getFieldMeta(endAfterCountFieldName).error}
      </p>
    </div>
  );
}
