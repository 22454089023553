const StandingChecklist = [
    {
        "title": "Desk height",
        "items": [
            "Desk height tailored to your height. Elbows are at 90 degrees and the same height as the desk, when standing in neutral without hunching forward",
            "Always note your footwear variation. Heels and flip flops should NOT be worn at a standing desk",
        ]
    },
    {
        "title": "Monitor height",
        "items": [
            "Eye gaze should be on the top third of the screen",
            "Screen should be 20-28 inches away from you when standing tall. Screen should be tilted 20 degrees backward",
            "Your chin should be at a 90 degree angle from your neck"
        ]
    },
    {
        "title": "Keyboard & mouse",
        "items": [
            "Keyboard and mouse should be positioned so you are able to maintain 90 degree angle at elbow, or slightly greater",
            "If elbows are set slightly greater than 90 degrees, the desk may be slightly lowered. In this instance adjust screen, keyboard and mouse as described above in order to maintain neutral stance posture",
            "Wrists should be at 180 degrees to the forearm and mouse should be close to keyboard"
        ]
    },
    {
        "title": "Standing desk posture",
        "items": [
            "Feet positioned hip width apart, even weight distribution between feet",
            "Knees slightly bent",
            "Pelvis in neutral, hip to hip line parallel to floor. Avoid preference for leaning into one leg too often",
            "Maintain natural lengthened curves of your spine. Imagine elongating tailbone to floor while lifting out of your hips and pulling through the top of your head towards the ceiling",
            "Avoid hunching, maintain an open chest by drawing the tip of each shoulder blade towards the opposing butt cheek, marking an 'X'",
            "Gently retract your chin as you lift through the top of your head. Your ears should be in line with your shoulders. Avoid poking your chin out"
        ]
    },
    {
        "title": "Movement/changing posture",
        "items": [
            "AVOID uninterrupted postures (standing or seated) for long periods of time ",
            "Aim to 'move'/ change posture/ positions/ stretch every 30 mins",
            "Start gradually when starting to use a standing desk. Allow your body to get used to the new load/positions"
        ]
    }
]

export { StandingChecklist };