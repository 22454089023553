import {
  Alert,
  Card,
  CardHeader,
  Col,
  Row,
  CardBody,
  UncontrolledAlert,
} from "reactstrap";
import Loading from "../../components/Loading";
import TimelineBlock from "../../components/Timeline/TimelineBlock";
import { MyUsageHeatmap } from "../../components/UsageHeatmap/UsageHeatmap";
import { TaskAssignment } from "../../interfaces/models/TaskAssignment";
import useDashboardTasks from "../../hooks/useDashboardTasks";
import { TaskSequence } from "../../interfaces/models/TaskSequence";
import { useEffect, useState } from "react";
import {
  DESK_COACH,
  WORKHEALTH_COACH,
} from "../../components/Routes/routingConstants";
import TasksCompletedActivityRing from "../../components/Dashboard/ActivityRings/TasksCompletedActivityRing";
import WeeklyChallengeCard from "../../components/Dashboard/WeeklyChallengeCard";
import WeeklyTopicCard from "../../components/Dashboard/WeeklyTopicCard";
import HealthHubCarousel from "../../components/Dashboard/HealthHubCarousel";

const DashboardPage = () => {
  const [dashboardView, setDashboardView] = useState<string>(null);
  const [dashboardTaskAssignments, setDashboardTaskAssignments] = useState<
    TaskAssignment[]
  >([]);
  const [dashboardTaskSequence, setDashboardTaskSequence] =
    useState<TaskSequence>(null);

  const [heatmapIsExpanded, setHeatmapIsExpanded] = useState<boolean>(false);

  const adjustLayoutOnHeatmapPeriodChange = (shouldExpand: boolean) => {
    setHeatmapIsExpanded(shouldExpand);
  };

  const { data, loading, error } = useDashboardTasks();
  const products = data?.me.user_license?.products
    ?.split(",")
    .map((product: string) => {
      return product.trim();
    });
  
  const firstProduct = products?.[0] || null

  useEffect(() => {
    if (data) {
      // Set dashboard view
      if (dashboardView === WORKHEALTH_COACH) {
        let taskAssignments: TaskAssignment[] = [];

        if (data.workhealth_task_assignments !== null) {
          taskAssignments = data.workhealth_task_assignments.map((wTA: any) => {
            return { ...wTA, task: wTA.workhealth_task };
          });
        }
        setDashboardTaskAssignments(taskAssignments);

        if (data.me.workhealth_sequence !== null) {
          // Filter out any null tasks in Sequence
          const filteredWHTaskSequence =
            data.me.workhealth_sequence.sequence.filter((WSeq: any) => {
              return WSeq.workhealth_task != null;
            });
          const whTaskSequence = filteredWHTaskSequence.map((WSeq: any) => {
            return { ...WSeq, task: { ...WSeq.workhealth_task } };
          });
          const dashboardTaskSequence = {
            _id: data.me.workhealth_sequence._id,
            sequence: whTaskSequence,
          };
          setDashboardTaskSequence(dashboardTaskSequence);
        }
      } else if (dashboardView === DESK_COACH) {
        setDashboardTaskAssignments(data.task_assignments);
        if (data.me.task_sequence != null) {
          // Filter out null any null tasks in Sequence
          const filteredTaskSequence = data.me.task_sequence.sequence.filter(
            (s) => {
              return s.task != null;
            }
          );
          const dashboardTaskSequence = {
            _id: data.me.task_sequence._id,
            sequence: filteredTaskSequence,
          };
          setDashboardTaskSequence(dashboardTaskSequence);
        }
      } else if (data.me.user_license?.products?.length > 0) {
        setDashboardView(firstProduct);
      }
    }
  }, [data, dashboardView, firstProduct]);

  const userIsLicensed: boolean = products?.length > 0 ? true : false;

  return (
    <>
      {loading && <Loading />}
      {error && (
        <Alert color="danger">
          Oops, we’re having trouble refreshing this connection. Please give it a minute, and if it doesn’t resolve, let us know.
        </Alert>
      )}
      {data && (
        <Col>
          <Row>
            <Col
              xl={userIsLicensed ? "6" : "10"}
              className="mb-md-4 order-2 order-xl-1"
            >
              {dashboardTaskAssignments.filter(
                (ta) =>
                  ta.task_complete === true && ta.user_acknowledged === true
              ).length >= 6 && (
                <Row>
                  <Col>
                    <UncontrolledAlert color="success" fade={false}>
                      <span className="alert-inner--icon">
                        <i className="ni ni-like-2" />
                      </span>
                      <span className="alert-inner--text">
                        <strong>Congratulations!</strong> You've done it
                      </span>
                    </UncontrolledAlert>
                  </Col>
                </Row>
              )}
              {userIsLicensed && (
                <>
                  <Row>
                    <Col lg="6" className="mb-4">
                      <WeeklyTopicCard />
                    </Col>
                    <Col lg="6" className="mb-4">
                      <TasksCompletedActivityRing
                        assignments={dashboardTaskAssignments}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {!heatmapIsExpanded && (
                      <Col lg="6" className="mb-4">
                        <WeeklyChallengeCard />
                      </Col>
                    )}
                    <Col lg={heatmapIsExpanded ? "12" : "6"} className="mb-4">
                      <MyUsageHeatmap
                        isWorkHealth={dashboardView === WORKHEALTH_COACH}
                        expandHeatmapCallback={
                          adjustLayoutOnHeatmapPeriodChange
                        }
                      />
                    </Col>
                  </Row>
                  {heatmapIsExpanded && (
                    <Row>
                       <Col lg="6" className="mb-4">
                          <WeeklyChallengeCard />
                        </Col>
                    </Row>
                  )}
                </>
              )}
              <HealthHubCarousel />
            </Col>
            {userIsLicensed && (
              <Col xl="6" className="order-1 order-xl-2 mb-4">
                <Card>
                  <CardHeader>
                    <h3>Achievement Log</h3>
                  </CardHeader>
                  <CardBody>
                    <TimelineBlock
                      task_sequence={dashboardTaskSequence}
                      task_assignments={dashboardTaskAssignments}
                      isWorkHealth={dashboardView === WORKHEALTH_COACH}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Col>
      )}
    </>
  );
};

export { DashboardPage as Dashboard };
