import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import { Input } from "reactstrap";
import FormError from "./FormError";
import { getImageUrl, isUrlValidImage } from "../../../helper/getImageUrl";
import { Instructions } from "../../../interfaces/models/Task";
import styles from "../../../assets/css/EditTaskModal.module.css";

interface Props {
  name: string;
  index: number;
  fileName: string;
  imgUrl: string;
  deselectMedia: (index: number) => void;
}


export default function ImageSelectedCard(props: Props) {
  const formikContext = useFormikContext();

  const imageUrl = isUrlValidImage(props.imgUrl)
    ? props.imgUrl
    : getImageUrl(props.imgUrl);

  // TODO: This component is not re-usable - in future wee should abstract the instructions into its own file - and make this one re-usable
  const instructions_field_name = `instructions.${props.index}`;
  const instructions_field_meta = formikContext.getFieldMeta<Instructions>(
    instructions_field_name
  );

  return (
    <div className="flex flex-col px-2">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="d-flex align-items-center">
          <img
            style={{ maxHeight: "100px", borderRadius: ".3rem" }}
            src={imageUrl}
            alt="thumbnail-img"
          />
          <div className="ml-2" style={{ maxWidth: "28rem" }}>
            <p>{props.fileName}</p>
          </div>
        </div>
        <Row>
          <Col>
            <a target="_blank" rel="noopener noreferrer" href={imageUrl}>
              <i className="fas fa-download" />
            </a>
          </Col>
          <Col>
            <i
              className={`fas fa-trash-alt ${styles.deleteIcon}`}
              onClick={() => props.deselectMedia(props.index)}
            />
          </Col>
        </Row>
      </div>
      <div className={`${styles.mediaFormInput}`}>
        <Input
          name={`instructions.${props.index}`}
          placeholder={
            "Enter any instructions... (your first line will be displayed in bold)"
          }
          type="textarea"
          style={{
            resize: "none",
          }}
          onChange={(e) => {
            formikContext.setFieldValue(instructions_field_name, {
              title: null,
              text: e.target.value,
            });
          }}
          value={instructions_field_meta.value?.text}
        />
      </div>
      <FormError>{instructions_field_meta.error?.["text"]}</FormError>
    </div>
  );
}
