import { useMemo, useState } from "react";
import Loading from "../../components/Loading";
import { PerformanceGraph } from "../../components/Chart";
import { Alert, Col, Row, Card, Button, Modal, ModalBody } from "reactstrap";
import { gql, useQuery } from "@apollo/client";
import { UserUsageHeatmap } from "../../components/UsageHeatmap/UsageHeatmap";
import ManageUsersTable from "./ManageUsersTable";
import UsageStatCard from "./UsageStatCard";
import { UsageIndicator, TableMode, DisplayMode, SortMode } from "./UserTableInterfaces";
import UserTrackingTable from "./UserTrackingTable";
import { User } from "../../interfaces/models/User";
import TableLegends from "./TableLegends";
import UserTableHeader from "./UserTableHeader";
import "../../_css/management.css";

const ManagementPage = () => {
  const { loading, data, error } = useQuery(gql`
    query getUsers {
      users {
        _id
        username
        name
        checklist
        task_assignments(sort: USER_ID_ASC) {
          user_acknowledged
          user_acknowledged_at
          task_complete
        }
      }
    }
  `);

  const [show, setShow] = useState<boolean>(false);
  const [usageModal, setUsageModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(null);

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.Weekly);
  const [sortState] = useState<SortMode>({ field: "", order: "asc" });

  const [tableMode, setTableMode] = useState<TableMode>();
  const [tableOpen, setTableOpen] = useState<boolean>(false);

  const [inspectMode, setInspectMode] = useState<boolean>(false);
  const [periods, setPeriods] = useState<string[]>([]);

  const performanceGraphData = useMemo(() => {
    if (data) {
      const allUserTaskAssignments = data.users.reduce((acc, user) => {
        return [
          ...acc,
          ...user.task_assignments.filter(
            (ta) =>
              ta &&
              ta.user_acknowledged &&
              ta.task_complete &&
              ta.user_acknowledged_at
          ),
        ];
      }, []);
      return allUserTaskAssignments;
    }
  }, [data]);

  // Calculate and collect all onboarded, low touch, and burnout users.
  const { onboardedUsers, lowTouchUsers, burnoutUsers } = useMemo(() => {
    if (!data)
      return {
        checklist_result: [],
        lowTouchUsers: [],
        burnoutUsers: [],
      };

    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    const onboardedUsers: User[] = [];
    const lowTouchUsers: User[] = [];
    const burnoutUsers: User[] = [];

    data.users.forEach((user: User) => {
      const dailyCompletedTallyMap = {};
      const completedTaskLastWeek = user.task_assignments.filter(
        (assignment) => {
          const ackDate = new Date(assignment.user_acknowledged_at);
          if (ackDate >= lastWeek) {
            const day = ackDate.toDateString();
            dailyCompletedTallyMap[day] = 0;
            return assignment.task_complete;
          }
          return false;
        }
      );
      completedTaskLastWeek.forEach((ta) => {
        const day = new Date(ta.user_acknowledged_at).toDateString();
        dailyCompletedTallyMap[day]++;
      });
      const incomplete = Object.values(dailyCompletedTallyMap).find(
        (dayTally) => dayTally <= 4
      );

      if (user.checklist) {
        onboardedUsers.push(user);
      }
      if (incomplete >= 0) {
        lowTouchUsers.push(user);
      }
      const burnoutIncomplete = Object.values(dailyCompletedTallyMap).find(
        (dayTally) => dayTally > 9
      );
      if (burnoutIncomplete >= 0) {
        burnoutUsers.push(user);
      }
    });
    return {
      onboardedUsers,
      lowTouchUsers: lowTouchUsers,
      burnoutUsers: burnoutUsers,
    };
  }, [data]);

  const handleSelectTableMode = (indicator: UsageIndicator) => {
    if (tableMode?.indicator === indicator) {
      // Close table if selected indicator is currently opened.
      setTableOpen(false);
      return;
    }
    switch (indicator) {
      case UsageIndicator.Checklist:
        setTableMode(Checklist);
        break;
      case UsageIndicator.LowTouch:
        setTableMode(LowTouch);
        break;
      case UsageIndicator.Burnout:
        setTableMode(Burnout);
        break;
    }
    setTableOpen(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const rowViewButton = (cell, row) => {
    return (
      <Button
        className="btn-sm"
        onClick={() => {
          setUsageModal(true);
          setUserId(row._id);
          show && <Modal handleClose={hideModal} />;
        }}
      >
        View Details
      </Button>
    );
  };

  const Checklist: TableMode = {
    indicator: UsageIndicator.Checklist,
    title: "Checklist Outstanding",
    description: "Users who have not yet been fully onboarded",
    data: onboardedUsers,
  };
  const LowTouch: TableMode = {
    indicator: UsageIndicator.LowTouch,
    title: "Low Touch",
    description:
      "Users who have acknowledged less than 4 nudges (7 day average)",
    data: lowTouchUsers,
  };
  const Burnout: TableMode = {
    indicator: UsageIndicator.Burnout,
    title: "Burnout Risk",
    description: "Users who have acknowledged 9 or more nudges (7 day average)",
    data: burnoutUsers,
  };

  return (
    <>
      {loading && <Loading />}
      {error && <Alert color="danger">{error.message}</Alert>}
      {data && (
        <div>
          <Modal
            isOpen={usageModal}
            toggle={() => {
              setUsageModal(false);
              setUserId(null);
            }}
            className="modal-lg"
          >
            <ModalBody>
              {userId && <UserUsageHeatmap user_id={userId} expandHeatmapCallback={()=>{}} />}
            </ModalBody>
          </Modal>
          <Row>
            <UsageStatCard
              tableMode={Checklist}
              stat={onboardedUsers.length}
              tableOpen={tableMode?.indicator === UsageIndicator.Checklist}
              selectTableMode={() =>
                handleSelectTableMode(UsageIndicator.Checklist)
              }
              colour="bg-primary"
            />
            <UsageStatCard
              tableMode={LowTouch}
              stat={lowTouchUsers.length}
              tableOpen={tableMode?.indicator === UsageIndicator.LowTouch}
              selectTableMode={() =>
                handleSelectTableMode(UsageIndicator.LowTouch)
              }
              colour="bg-warning"
            />
            <UsageStatCard
              tableMode={Burnout}
              stat={burnoutUsers.length}
              tableOpen={tableMode?.indicator === UsageIndicator.Burnout}
              selectTableMode={() =>
                handleSelectTableMode(UsageIndicator.Burnout)
              }
              colour="bg-danger"
            />
          </Row>
          {tableMode && (
            <Row>
              <Col lg="12">
                <Card>
                  <UserTrackingTable
                    title={tableMode.title}
                    description={tableMode.description}
                    tableData={tableMode.data}
                    sortState={sortState}
                    rowViewButton={rowViewButton}
                    tableOpen={tableOpen}
                  />
                </Card>
              </Col>
            </Row>
          )}

          {/* Weekly Summary - Average Performance - All Users - Performance Chart/Graph */}
          <Row>
            <UserTableHeader 
              displayMode={displayMode}
              setDisplayMode={setDisplayMode}
              inspectMode={inspectMode}
              setInspectMode={setInspectMode}
              periods={periods}
            />
            
            <Col md={12}>
              <TableLegends />
            </Col>
          </Row>

          <Col lg="12">
            <PerformanceGraph
              width={900}
              height={370}
              data={performanceGraphData}
              displayMode={DisplayMode[displayMode]}
              inspectMode={inspectMode}
              periods={periods}
              setInspectMode={setInspectMode}
              setPeriods={setPeriods}
            />
          </Col>

          <ManageUsersTable
            data={data.users}
            sortState={sortState}
            rowViewButton={rowViewButton}
          />
        </div>
      )}
    </>
  );
};

export { ManagementPage as Management };
