import React from "react";
import ImmersiveSidebar from "../components/Sidebar/ImmersiveSidebar.js";
import PlayVideoPage from "../pages/Video/PlayVideoPage";
import { dashboardRoutes } from "../components/Routes/routingConstants";

interface VideoLayoutProps {
  history: any;
  location: any;
  match: any;
  staticContext?: any;
}

export default function VideoLayout(props: VideoLayoutProps) {
  const mainContent = React.useRef(null);

  return (
    <>
      <ImmersiveSidebar
        {...props}
        routes={dashboardRoutes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/deskcoach-dark.svg").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <PlayVideoPage {...props} />
      </div>
    </>
  );
};
