import { useState } from "react";
import BootstrapTable, {
  ColumnFormatter,
  SortOrder,
} from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Col, Row, Card, Button, Collapse, Badge } from "reactstrap";
import PaginationMenu from "../../components/Tables/PaginationMenu";
import { User } from "../../interfaces/models/User";

interface ManageUsersTableProps {
  data: User[];
  sortState: any;
  rowViewButton: ColumnFormatter<any, any, any>;
}

const { SearchBar } = Search;

const rowOnboarded = (cell, row) => {
  if (!cell) cell = false;
  return (
    <Badge color="" className="badge-dot">
      {cell ? (
        <span>
          <i className="bg-success" />
          onboard
        </span>
      ) : (
        <span>
          <i className="bg-warning" />
          not onboarded
        </span>
      )}
    </Badge>
  );
};

export default function ManageUsersTable({
  data,
  sortState,
  rowViewButton,
}: ManageUsersTableProps) {
  const [userTable, setUserTable] = useState({
    open: false,
    text: "Show Users",
  });

  const toggleUserTable = () => {
    if (userTable.open === false) {
      setUserTable({ open: true, text: "Hide Users" });
    }
    if (userTable.open === true) {
      setUserTable({ open: false, text: "Show Users" });
    }
  };

  return (
    <Row>
      <Col lg="12">
        <Card>
          <Button
            color="default"
            outline
            className="mr-0"
            onClick={() => toggleUserTable()}
          >
            {userTable.text}
          </Button>
          <Collapse isOpen={userTable.open}>
            <ToolkitProvider
              data={data}
              keyField="_id"
              columns={[
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                  formatter: (_, row) => row.name ?? "Unknown",
                },
                {
                  dataField: "checklist",
                  text: "Onboarded",
                  sort: true,
                  formatter: rowOnboarded,
                },
                {
                  dataField: "button",
                  text: "",
                  sort: false,
                  formatter: rowViewButton,
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter px-4 pb-1"
                  >
                    <label>
                      Search:
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </label>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    pagination={PaginationMenu}
                    bordered={false}
                    sort={{
                      dataField: sortState.field,
                      order: sortState.order as SortOrder,
                    }}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Collapse>
        </Card>
      </Col>
    </Row>
  );
}
