import { useFormikContext } from "formik";
import { EmpowerTask } from "../../../interfaces/models/EmpowerTask";

export interface AccordionSelectorFieldProps {
  empowerTask: EmpowerTask;
  isSelected: boolean;
  onSelect: (task: EmpowerTask) => void;
  name: string;
}

export function AccordionSelectorField({
  empowerTask,
  isSelected,
  onSelect,
  name,
}: AccordionSelectorFieldProps) {
  const formikCtx = useFormikContext();

  return (
    <div
      className={`accordion-task-selectable ${
        isSelected && "accordion-task-selected"
      }`}
      onClick={() => {
        if (isSelected) {
          onSelect(null);
          formikCtx.setFieldValue(name, null);
        } else {
          onSelect(empowerTask);
          formikCtx.setFieldValue(name, empowerTask._id);
        }
      }}
    >
      {isSelected && (
        <i className="fas fa-check fa-lg accordion-caret pr-3 text-primary" />
      )}
      <span className="m-0 flex-grow-1" style={{ fontSize: 16 }}>
        {empowerTask.name}
      </span>
    </div>
  );
}
