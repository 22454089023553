import React, { useState } from "react";
import { ApolloError, gql, useQuery } from "@apollo/client";
import moment from "moment";
import Loading from "../Loading";
import CalendarHeatmap from "react-calendar-heatmap";
import { TaskAssignment } from "../../interfaces/models/TaskAssignment";
import {
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { heatmapPeriods } from "./HeatmapPeriods";
import { HeatmapPeriod } from "../../interfaces/HeatmapPeriod";

const WEEKDAYLABEL_LEFT = 0;

CalendarHeatmap.prototype.getTransformForWeekdayLabels = function () {
  if (this.props.horizontal) {
    return `translate(${WEEKDAYLABEL_LEFT}, ${this.getMonthLabelSize()})`;
  }
  return null;
};

CalendarHeatmap.prototype.getTransformForAllWeeks = function () {
  if (this.props.horizontal) {
    return `translate(${WEEKDAYLABEL_LEFT + 22}, ${this.getMonthLabelSize()})`;
  }
  return null;
};

CalendarHeatmap.prototype.getHeight = function () {
  return (
    this.getWeekWidth() + (this.getMonthLabelSize() - this.props.gutterSize)
  );
};

const startDate = moment().subtract(6, "months");
const today = moment();

interface MyUsageHeatmapProps {
  isWorkHealth: boolean;
  expandHeatmapCallback: (shouldExpand: boolean) => void;
}

function MyUsageHeatmap({
  isWorkHealth,
  expandHeatmapCallback,
}: MyUsageHeatmapProps) {
  const { data, loading, error } = useQuery(
    gql`
      query getTimeline($from: Date!) {
        task_assignments(filter: { from: $from }, limit: 2000) {
          user_acknowledged
          user_acknowledged_at
          task_complete
        }
        workhealth_task_assignments(filter: { from: $from }, limit: 2000) {
          user_acknowledged
          user_acknowledged_at
          task_complete
        }
      }
    `,
    {
      variables: { from: startDate.format("YYYY-MM-DDTHH:mm:ss") },
    }
  );

  let task_assignments = data?.task_assignments;
  if (isWorkHealth) {
    task_assignments = data?.workhealth_task_assignments;
  }

  return (
    <UsageHeatmap
      task_assignments={task_assignments}
      loading={loading}
      error={error}
      expandHeatmapCallback={expandHeatmapCallback}
    />
  );
}

type UsageTimelineData = {
  date: string;
  count: number;
};

interface UsageHeatmapProps {
  task_assignments: TaskAssignment[];
  loading: boolean;
  error: ApolloError;
  expandHeatmapCallback?: (expandHeatmap: boolean) => void;
}

const UsageHeatmap = ({
  task_assignments,
  loading,
  error,
  expandHeatmapCallback,
}: UsageHeatmapProps) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [heatmapPeriod, setHeatmapPeriod] = useState<HeatmapPeriod>(
    heatmapPeriods[0]
  );
  const setPeriod = (period: HeatmapPeriod) => {
    setHeatmapPeriod(period);

    if (period === heatmapPeriods[0]) {
      expandHeatmapCallback(false);
    } else {
      expandHeatmapCallback(true);
    }
  };

  const timelineData: UsageTimelineData[] = [];
  // TODO: should this be a memoized?
  if (task_assignments) {
    task_assignments.reduce((result, ta) => {
      if (ta.user_acknowledged && ta.task_complete) {
        var date = moment(ta.user_acknowledged_at).format("YYYY-MM-DD");
        var index = timelineData.findIndex((td) => td.date === date);

        if (index !== -1) timelineData[index].count++;
        else {
          timelineData.push({
            date: date,
            count: 1,
          });
        }
      }
      return [];
    }, []);
  }

  const toggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  return (
    <Card className="h-100">
      <CardBody>
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h3>Usage</h3>
          <Dropdown
            className="usage-heatmap-period-dropdown"
            isOpen={dropdownOpen}
            toggle={toggle}
            direction={"down"}
          >
            <DropdownToggle caret>{heatmapPeriod.title}</DropdownToggle>
            <DropdownMenu>
              {heatmapPeriods.map((period, i) => {
                return (
                  <DropdownItem key={i} onClick={() => setPeriod(period)}>
                    {period.title}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        {loading && <Loading />}
        {error && error.message}
        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
          {task_assignments && (
            <>
              <CalendarHeatmap
                showOutOfRangeDays={true}
                startDate={heatmapPeriod.startDate}
                endDate={today.toDate()}
                values={timelineData}
                showWeekdayLabels={true}
                classForValue={(value: UsageTimelineData) => {
                  if (!value) {
                    return "usage-heatmap-empty";
                  }
                  if (value.count > 16) return "usage-heatmap-16";
                  return `usage-heatmap-${value.count}`;
                }}
                transformDayElement={(element, value, index) =>
                  React.cloneElement(element, { rx: "3", ry: "3" })
                }
              />
              <UsageHeatmapScale />
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const UsageHeatmapScale = () => {
  return (
    <div className="usage-heatmap-scale float-right mt-2">
      <small>Less</small>
      <svg width="12" height="12">
        <rect
          width="12"
          height="12"
          className="usage-heatmap-1"
          rx="1"
          ry="1"
        ></rect>
      </svg>
      <svg width="12" height="12">
        <rect
          width="12"
          height="12"
          className="usage-heatmap-3"
          rx="1"
          ry="1"
        ></rect>
      </svg>
      <svg width="12" height="12">
        <rect
          width="12"
          height="12"
          className="usage-heatmap-9"
          rx="1"
          ry="1"
        ></rect>
      </svg>
      <svg width="12" height="12">
        <rect
          width="12"
          height="12"
          className="usage-heatmap-11"
          rx="1"
          ry="1"
        ></rect>
      </svg>
      <svg width="12" height="12">
        <rect
          width="12"
          height="12"
          className="usage-heatmap-12"
          rx="1"
          ry="1"
        ></rect>
      </svg>
      <small>More</small>
    </div>
  );
};

/* 
    This component is being used on the Management Page.
    TODO: is this component, and the ManagementPage.js needed still?
*/
const UserUsageHeatmap = (props) => {
  const { loading, data, error } = useQuery(
    gql`
      query getTimeline($user_id: MongoID!) {
        user(_id: $user_id) {
          task_assignments(limit: 2000) {
            user_acknowledged
            user_acknowledged_at
            task_complete
          }
        }
      }
    `,
    {
      variables: { user_id: props.user_id },
    }
  );

  return (
    <>
      {loading && <Loading />}
      {error && error.message}
      {data && (
        <UsageHeatmap
          task_assignments={data.user.task_assignments}
          loading={loading}
          error={error}
          expandHeatmapCallback={props.expandHeatmapCallback}
        />
      )}
    </>
  );
};

export { MyUsageHeatmap, UserUsageHeatmap };
