import { useState, useMemo } from "react";
import { Col, Row, Alert, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { TaskSequence } from "../../interfaces/models/TaskSequence";
import TaskViewModal from "./TaskViewModal";
import { TaskCard } from "./TaskCard";
import EditTaskModal from "./EditTaskModal";
import { useIsUserSuperAdmin } from "../../hooks/useIsUserSuperAdmin";
import { Task } from "../../interfaces/models/Task";

interface ModalUsage {
  taskId: string;
  isStream: boolean;
}
interface TaskSequenceProps {
  sequence: TaskSequence;
  initialTaskId: string;
  isWorkHealth?: boolean;
}

const TaskSequenceCard = ({
  sequence,
  initialTaskId,
  isWorkHealth = false,
}: TaskSequenceProps) => {
  const [usageModal, setUsageModal] = useState<ModalUsage>({
    taskId: initialTaskId,
    isStream: false,
  });

  const [editTask, setEditTask] = useState<Task>(null);
  // const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const title = sequence.name;
  const tasks = useMemo(() => {
    // Filter out any null tasks in sequence
    return sequence?.sequence?.filter((s) => s.task != null).map((t) => t.task);
  }, [sequence]);

  const getTask = (taskId: string) => {
    return tasks?.find((t) => t._id === taskId);
  };

  const openModal = (taskId: string) => {
    setUsageModal({
      taskId: taskId,
      isStream: false,
    });
  };
  const openStream = (taskId: string) => {
    setUsageModal({
      taskId: taskId,
      isStream: true,
    });
  };
  const setUsage = (usage: ModalUsage) => {
    setUsageModal(usage);
  };

  const openEditModal = (editTask: Task) => {
    setEditTask(editTask);
    // setShowEditModal(true);
  };

  const userIsSuperAdmin: boolean = useIsUserSuperAdmin();

  return (
    <>
      {tasks && tasks?.length === 0 && (
        <Alert color="warning">No tasks found</Alert>
      )}
      {/* Modal shown when clicking on a Task */}
      <TaskViewModal
        usageModal={usageModal}
        setUsage={setUsage}
        task={getTask(usageModal.taskId)}
        isVideo={!isWorkHealth}
      />
      {editTask && (
        <EditTaskModal
          initialTask={editTask}
          onClose={() => setEditTask(null)}
        />
      )}
      {/* TODO: abstract this to be a better re-used component - likely higher up the page hierachy */}
      <div className="w-100">
        <Breadcrumb listTag="div">
          <BreadcrumbItem>
            <Link to=".">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>{title}</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <br />
      <h3 className="mb-4">Category: {title}</h3>
      <Row className="animated-card" style={{ margin: "1%" }}>
        {tasks && tasks.length > 0 && (
          <Row className="mb-4">
            {tasks.map(
              (
                {
                  _id: id,
                  action_url,
                  name,
                  description,
                  images,
                  instructions,
                },
                i
              ) => {
                return (
                  <Col key={id} xs={12} lg={4} className="py-2 px-1">
                    <TaskCard
                      {...{
                        id,
                        action_url,
                        title: name,
                        category: title,
                        body: description,
                        types: [],
                        index: i,
                        instructions,
                      }}
                      openStream={() => openStream(id)}
                      openModal={() => openModal(id)}
                      openEditModal={() =>
                        openEditModal({
                          _id: id,
                          name,
                          description,
                          images,
                          instructions,
                          action_url,
                        })
                      }
                      images={images}
                      isWorkHealth={isWorkHealth}
                      canEdit={i !== 0 && userIsSuperAdmin}
                    />
                  </Col>
                );
              }
            )}
          </Row>
        )}
      </Row>
    </>
  );
};

export default TaskSequenceCard;
