import { Dashboard } from "../../pages/Dashboard/DashboardPage";
import { Management } from "../../pages/Management/ManagementPage";
import { Intention } from "../../pages/IntentionCoach/IntentionCoachPage";
import { DeskCoach } from "../../pages/DeskCoach/DeskCoachPage";
import { ViewTaskSequencePage } from "../../pages/DeskCoach/ViewTaskSequencePage";
import MovementCoachPage from "../../pages/MovementCoach/MovementCoachPage";
import EmpowerMePage from "../../pages/EmpowerMe/EmpowerMePage";
import { WorkHealth } from "../../pages/WorkHealthCoach/WorkHealthCoachPage";
import { ViewWorkHealthTaskSequencePage } from "../../pages/WorkHealthCoach/ViewWorkHealthTaskSequencePage";

export const DASHBOARD_ROOT = "/dash";
export const MOVEMENT_COACH = "movement.coach";
export const EMPOWER_ME = "empower.me";
export const DESK_COACH = "desk.coach";
export const WORKHEALTH_COACH = "workhealth.coach";
export const MY_INTENTION_COACH = "myintention.coach";

type DashboardRoute = {
  adminRequired: boolean;
  licenseRequired?: string;
  path: string;
  name: string;
  icon: string;
  component: () => JSX.Element;
  showInSidebar: boolean;
  exact?: boolean;
};

export const dashboardRoutes: DashboardRoute[] = [
  {
    adminRequired: false,
    path: "/dash/user" || "/dash",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    showInSidebar: true,
  },
  {
    adminRequired: true,
    path: `${DASHBOARD_ROOT}/management`,
    name: "Management",
    icon: "ni ni-bulb-61 text-primary",
    component: Management,
    showInSidebar: true,
  },
  {
    adminRequired: false,
    licenseRequired: DESK_COACH,
    path: `${DASHBOARD_ROOT}/${DESK_COACH}`,
    name: DESK_COACH,
    icon: "fa fa-bell text-primary",
    component: DeskCoach,
    showInSidebar: false,
    exact: true,
  },
  {
    adminRequired: false,
    licenseRequired: WORKHEALTH_COACH,
    path: `${DASHBOARD_ROOT}/${WORKHEALTH_COACH}`,
    name: WORKHEALTH_COACH,
    icon: "fas fa-star text-primary",
    component: WorkHealth,
    showInSidebar: true,
    exact: true,
  },
  {
    adminRequired: false,
    licenseRequired: DESK_COACH,
    path: `${DASHBOARD_ROOT}/${DESK_COACH}/:taskSequenceId`,
    name: DESK_COACH,
    icon: "fa fa-bell text-primary",
    component: ViewTaskSequencePage,
    showInSidebar: false,
  },
  {
    adminRequired: false,
    licenseRequired: WORKHEALTH_COACH,
    path: `${DASHBOARD_ROOT}/${WORKHEALTH_COACH}/:workhealthTaskSequenceId`,
    name: WORKHEALTH_COACH,
    icon: "fa fa-bell text-primary",
    component: ViewWorkHealthTaskSequencePage,
    showInSidebar: false,
  },
  {
    adminRequired: false,
    licenseRequired: DESK_COACH,
    path: `${DASHBOARD_ROOT}/${MY_INTENTION_COACH}`,
    name: MY_INTENTION_COACH,
    icon: "fa fa-bullseye text-primary",
    component: Intention,
    showInSidebar: true,
  },
  {
    adminRequired: false,
    licenseRequired: DESK_COACH,
    path: `${DASHBOARD_ROOT}/${MOVEMENT_COACH}`,
    name: "movement.coach",
    icon: "fa fa-heart text-primary",
    component: MovementCoachPage,
    showInSidebar: true,
    exact: false,
  },
  {
    adminRequired: false,
    licenseRequired: EMPOWER_ME,
    path: `${DASHBOARD_ROOT}/${EMPOWER_ME}`,
    name: "empower me",
    icon: "fas fa-rocket text-primary",
    component: EmpowerMePage,
    showInSidebar: true,
    exact: false,
  },
];
