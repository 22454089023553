export enum NudgeClickUrlType {
  Link,
  Video,
}

export const getNudgeClickUrlType = (url: string) => {
  if (url && url.includes("/video/") && url.includes("desk")) {
    return NudgeClickUrlType.Video;
  }
  return NudgeClickUrlType.Link;
};
