import { useState, useEffect , useCallback} from "react";
import { azureConfig } from "../config/auth/azure.config";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import Loading from "./Loading";
import { ApolloProvider } from "@apollo/client";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
// import _ from "lodash";
const SecureApp = (props) => {
  const { instance, inProgress, accounts } = useMsal();
  const [isInitialised, setIsInitialised] = useState(false);

  const asyncTokenLookup = useCallback(async () => {
    const request = {
      ...azureConfig,
      account: accounts[0],
    };

    const getAccounts = await instance.getAllAccounts();
    const account = getAccounts[0];
    if (account && inProgress === InteractionStatus.None) {
      try {
        const result = await instance.acquireTokenSilent({
          ...request,
          forceRefresh: true,
          account,
        });
        return result.idToken;
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenRedirect(request);
        }
      }
    } else if (!account && inProgress === InteractionStatus.None) {
      return instance.acquireTokenRedirect(request);
    }
  },[inProgress,instance, accounts]);
  
  const initialise = useCallback(async () => {
    await asyncTokenLookup();
    setIsInitialised(true);
  }, [asyncTokenLookup]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!isInitialised) {
    return <Loading />;
  } else {
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_DATA_API_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await asyncTokenLookup();
      return {
        headers: {
          ...headers,
          authorization: token,
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  }
};

export default SecureApp;
