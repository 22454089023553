import { gql, useQuery } from "@apollo/client";

export function useGetUsersTaskSequenceId() {
  return useQuery(
    gql`
      query getUserTaskSequences {
        me {
          task_sequence_id
        }
      }
    `
  );
}
