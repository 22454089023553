/*!
=========================================================
* Argon Dashboard React - v1.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  UncontrolledTooltip as Tooltip,
} from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

const handleLogout = (instance: IPublicClientApplication) => {
  instance.logout().catch((e: Error) => {
    console.error(e);
  });
};

interface AdminNavbarProps {
  brandText: string;
}

export const AdminNavbar = (props: AdminNavbarProps) => {
  const { instance, accounts } = useMsal();

  return (
    <>
      <Navbar className="navbar-top navbar-light" expand="md" id="navbar-main">
        <Container fluid>
          <p className="h4 mb-0 text-dark text-lowercase d-none d-lg-inline-block">
            {props.brandText}
          </p>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav id="login-out-button">
                <Media className="align-items-center">
                  <span>
                    <i className="ni ni-single-02"></i>
                    <i className="ml-2 ni ni-bold-down"></i>
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-arrow zindex-dropdown"
                right
              >
                <DropdownItem
                  href="#pablo"
                  onClick={() => handleLogout(instance)}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

          {Array.isArray(accounts) &&
            accounts.length > 0 &&
            accounts[0]?.idTokenClaims && (
              <Tooltip flip target="login-out-button">
                {
                  // @ts-ignore
                  `${accounts[0].idTokenClaims.given_name} ${accounts[0].idTokenClaims?.family_name}`
                }
              </Tooltip>
            )}
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
