const WorkFromHomeChecklist = [
    {
        "title": "General",
        "items": [
            "To avoid long periods of repeated actions, take regular and appropriate breaks",
            "Check you have a comfortable posture and set up",
            "Appropriate breaks include changing posture, stretching and resetting posture, alternating activity, leaving desk",
            "Use of correct lifting techniques and aids when performing moving/pushing/carrying tasks"
        ]
    },
    {
        "title": "Work Setting",
        "items": [
            "Appropriate level of ventilation and thermal/temperature conditions",
            "Check light/illumination conditions are suitable for activity. Fine visual tasks require more lighting, avoid glare on screens or working surfaces (artificial or natural sources)",
            "Check for trip hazards (e.g. electrical cords) and clutter impeding good ergonomic set up",
            "Check height, location and other physical characteristics (e.g. width) of desk/computer/chair/standing mat is suited to task",
            "Where possible, use equipment arranged by your organisation and has been recently tested and tagged",
            "Check for appropriate level of storage and ergonomic support of documents/books/other visual display units",
            "Ensure there is no damaged flooring (e.g. carpet pulled up,  broken tiles, rugs etc)",
            "Take a break from keyboard every 30-45mins and stand at LEAST once per hour",
            "Set up reasonable communication form with your line manager (e.g. email each morning)",
            "Inform manager of any changes to your health status immediately or situations that could impact your health status (e.g. moving house)",
            "PCBUs (Person Conducting Business or Undertaking) to ensure agreed communications strategy is documented",
            "Ensure you are aware of exit routes, fire exits, bathrooms, PPE (Personal Protective Equipment) and other WH&S interventions  (e.g. defibrillator, first aid kit)"
        ]
    },
    {
        "title": "Mental Health",
        "items": [
            "Establish boundaries around your work hours with your workplace/team and also family/housemates",
            "In order to maintain cohesive and positive work relationships, schedule regular meetings/catch ups with your manager/teams/clients",
            "During your work hours, stay connected (email, phone, online) in order to be aware of latest work/ team developments",
            "Play music or sound to create a balanced environment",
            "In order to maximise productivity, avoid potential distractions and implement strategies to avoid them (e.g. workstation location separate to rest of the home)",
            "Aim to regularly 'move' or exercise daily and use outdoor spaces to take breaks were possible",
            "Be aware of the organisation's Employee Assistance Programs availability and your capability to access if needed"
        ]
    }
]

export { WorkFromHomeChecklist };