import { ApolloError } from "@apollo/client";
import Loading from "../../components/Loading";

interface CompletionProps {
  loading?: boolean;
  error?: ApolloError;
}

export default function EmpowerTaskCompletion({
  loading,
  error,
}: CompletionProps) {
  const defaultErrorMessage = `There was a problem while creating your empower me task. Please try again later or contact a System Administrator.`;

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <p>Please wait while we're creating your empower me task...</p>
        <div className="p-5">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex flex-column align-items-center p-6">
        {error ? (
          <>
            <i className="fas fa-exclamation-triangle fa-5x text-danger p-5"></i>
            <h1>Sorry! Something went wrong...</h1>
            <div
              className="alert alert-error mt-8 modal-alert-msg modal-alert-error"
              role="alert"
            >
              {defaultErrorMessage}
            </div>
          </>
        ) : (
          <>
            <i className="fas fa-check-circle fa-5x text-success p-5"></i>
            <h1>Your empower me task has been created!</h1>
            <div
              className="alert alert-success mt-8 modal-alert-msg modal-alert-success"
              role="alert"
            >
              Please ensure you select '
              <span className="font-weight-bold">Sync</span>' from your app to
              see changes take effect on your device immediately.
            </div>
          </>
        )}
      </div>
    </>
  );
}
